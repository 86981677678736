/** @format */
import {useEffect, useMemo} from 'react';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, Grid, LinearProgress, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {add, format} from 'date-fns';

import adTypeHelpers from 'helpers/adTypeHelpers';
import reportHelpers from 'helpers/reportHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import Card from './Card';
import Header from './Header';

const GET_CURRENT_USER = gql`
  query GetCurrentUser(
    $platforms: String!
    $sensorName: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    currentUser {
      currentCompany {
        sensorExclusion(sensorName: $sensorName) {
          id
        }
        graphSensorChecks(startDate: $startDate, endDate: $endDate) {
          new
          old
          fixed
          collectedOn
          sensorName
        }
        lastChecks {
          checks
          cost
          found
          new
          sensor {
            name
            platform
            isError
          }
        }
        report(sensorNames: $sensorName, platforms: $platforms) {
          items {
            platform
            account
            accountId
            campaign
            campaignId
            adGroup
            adGroupId
            ad
            adId
            sitelink
            sitelinkId
            keyword
            keywordId
            firstDate
            isNew
            objects {
              analysisPeriod {
                name
                type
                value
              }
              bid {
                name
                type
                value
              }
              firstPageCpc {
                name
                type
                value
              }
              link {
                name
                type
                value
              }
              linkType {
                name
                type
                value
              }
              problemLink {
                name
                type
                value
              }
              host {
                name
                type
                value
              }
              date {
                name
                type
                value
              }
              spent {
                name
                type
                value
              }
              spentBefore {
                name
                type
                value
              }
              errwords {
                name
                type
                value
              }
              badWord {
                name
                type
                value
              }
              query {
                name
                type
                value
              }
              views {
                name
                type
                value
              }
              viewsBefore {
                name
                type
                value
              }
              ctr {
                name
                type
                value
              }
              ctrBefore {
                name
                type
                value
              }
              amountRemaining {
                name
                type
                value
              }
              averageSpend {
                name
                type
                value
              }
              viewsPerUser {
                name
                type
                value
              }
              targetLoc {
                name
                type
                value
              }
              clientLoc {
                name
                type
                value
              }
              campaignLoc {
                name
                type
                value
              }
              vcard {
                name
                type
                value
              }
              cardLoc {
                name
                type
                value
              }
              conversions {
                name
                type
                value
              }
              conversionsBefore {
                name
                type
                value
              }
              adTitle {
                name
                type
                value
              }
              adText {
                name
                type
                value
              }
              domainLoc {
                name
                type
                value
              }
              linkLoc {
                name
                type
                value
              }
              clicks {
                name
                type
                value
              }
              clicksBefore {
                name
                type
                value
              }
              utmParam {
                name
                type
                value
              }
              utmParamValue {
                name
                type
                value
              }
              limit {
                name
                type
                value
              }
              goalFullname {
                name
                type
                value
              }
              placement {
                name
                type
                value
              }
              comparisonPeriod {
                name
                type
                value
              }
              impressions {
                name
                type
                value
              }
              impressionsBefore {
                name
                type
                value
              }
              metric {
                name
                type
                value
              }
              metricDiff {
                name
                type
                value
              }
              metricDiffAbs {
                name
                type
                value
              }
              metricPreviousValue {
                name
                type
                value
              }
              metricValue {
                name
                type
                value
              }
              cpc {
                name
                type
                value
              }
              ratio {
                name
                type
                value
              }
              currencyCode {
                name
                type
                value
              }
            }
            maxLocationField
          }
        }
      }
    }
  }
`;

const CREATE_SENSOR_EXCLUSION = gql`
  mutation CreateSensorExclusion($sensorName: String!) {
    createSensorExclusion(sensorName: $sensorName) {
      id
    }
  }
`;

const DELETE_SENSOR_EXCLUSION = gql`
  mutation DeleteSensorExclusion($id: ID!) {
    deleteSensorExclusion(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: theme.spacing(6),
  },
  noIssuesText: {
    fontSize: 18,
    fontWeight: '400',
    lineHeight: '22px',
  },
}));

const Details = () => {
  const searchParams = routerHelpers.useSearchParams();
  const name = searchParams.get('name');

  const {currentAdType, currentCompany} = useCompanyContext();

  const classes = useStyles();

  const {mixpanel} = useMixpanelContext();

  const platforms = adTypeHelpers.platformsFromAdType(currentAdType);

  const now = new Date();
  const startDate = add(now, {days: -29});
  const endDate = now;

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    skip: !name,
    variables: {
      platforms: platforms.join(','),
      sensorName: name,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });

  const [deleteSensorExclusion, {loading: deleteSensorExclusionLoading}] =
    useMutation(DELETE_SENSOR_EXCLUSION);
  const [createSensorExclusion, {loading: createSensorExclusionLoading}] =
    useMutation(CREATE_SENSOR_EXCLUSION);

  routerHelpers.useCallbackOnReturn(refetch);

  const sortedGraphChecks = useMemo(() => {
    if (!data) return [];

    const {graphSensorChecks} = data.currentUser.currentCompany;

    return R.pipe(
      R.filter(({sensorName}) => sensorName === name),
      R.sortBy(v => v.collectedOn),
    )(graphSensorChecks);
  }, [data, name]);

  useEffect(() => {
    if (data && data.currentUser.currentCompany.report &&
      data.currentUser.currentCompany.report.items.length > 0) {
      mixpanel.track('open_sensor_details', {
        sensor_name: name,
        company_uuid: currentCompany.uuid,
      });
    }
  }, [data, mixpanel, name, currentCompany]);

  if (!name) return <Alert severity='error'>Не указан сенсор</Alert>;
  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {lastChecks: allLastChecks} = data.currentUser.currentCompany;

  const lastChecks = allLastChecks.filter(v => v.sensor.name === name);

  const {sensorExclusion, report} = data.currentUser.currentCompany;
  const {isError} = lastChecks[0].sensor;

  const reportItemGroups = reportHelpers.groupReportItems(name, report.items);

  const handleCreateSensorExclusion = async () => {
    if (sensorExclusion) return;

    await createSensorExclusion({variables: {sensorName: name}});
    refetch();
  };

  const handleDeleteSensorExclusion = async () => {
    if (!sensorExclusion) return;

    await deleteSensorExclusion({variables: {id: sensorExclusion.id}});
    refetch();
  };
  return (
    <>
      <Header
        sensorExclusionLoading={
          deleteSensorExclusionLoading ||
          createSensorExclusionLoading ||
          loading
        }
        sortedGraphChecks={sortedGraphChecks}
        lastChecks={lastChecks}
        reportItems={report.items}
        sensorExclusion={sensorExclusion}
        sensorName={name}
        onCreateSensorExclusion={handleCreateSensorExclusion}
        onDeleteSensorExclusion={handleDeleteSensorExclusion}
      />

      <Grid container className={classes.list} spacing={3}>
        {reportItemGroups.map((vs, i) => (
          <Card key={i} isError={isError} reportItems={vs} sensorName={name} />
        ))}
      </Grid>
      {reportItemGroups.length === 0 && (
        <Box mt={6}>
          <Typography className={classes.noIssuesText}>
            {isError ? (
              <Trans>Сегодня всё в порядке. Так держать!</Trans>
            ) : (
              <Trans>Сегодня нет ни одной рекомендации. Так держать!</Trans>
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Details;
