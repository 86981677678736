/** @format */
import {styled} from '@mui/material/styles';
import {Box, Button, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import {CHIER_REPORT_TABLE_TYPE} from 'pages/ChiefReport/types';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'ChiefReportButton';

const classes = {
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  buttonHeading: `${PREFIX}-buttonHeading`,
  button: `${PREFIX}-button`,
};

const ButtonsContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  background: 'transparent',
  borderRadius: '8px',
  border: '1px solid #383838',
  color: '#F1F1F1',
  fontSize: '14px',
  fontWeight: '400',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.buttonHeading}`]: {
    margin: theme.spacing(0, 2),
  },
  [`& .${classes.button}`]: {
    borderRadius: '8px',
    background: '#383838',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(3),
    '&:first-of-type': {
      marginRight: theme.spacing(0.5),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:nth-of-type(2)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));
const ChiefReportButton = () => {
  const navigate = useNavigate();

  const {mixpanel} = useMixpanelContext();

  const handleClick = (tableType: CHIER_REPORT_TABLE_TYPE) => {
    if (tableType === ROUTES.CHIEF_REPORT_SUMMARY) {
      mixpanel.track('open_chief_report_summary_from_interface');

      navigate(`/${ROUTES.CHIEF_REPORT}/${ROUTES.CHIEF_REPORT_SUMMARY}`);
    }

    if (tableType === ROUTES.CHIEF_REPORT_STAFF) {
      mixpanel.track('open_chief_report_staff_from_interface');

      navigate(`/${ROUTES.CHIEF_REPORT}/${ROUTES.CHIEF_REPORT_STAFF}`);
    }
  };

  return (
    <ButtonsContainer>
      <Typography className={classes.buttonHeading}>
        <Trans>Отчеты</Trans>
      </Typography>

      <Button className={classes.button} onClick={() => handleClick('summary')}>
        <Trans>Сводный</Trans>
      </Button>
      <Button className={classes.button} onClick={() => handleClick('staff')}>
        <Trans>По сотрудникам</Trans>
      </Button>
    </ButtonsContainer>
  );
};
export default ChiefReportButton;
