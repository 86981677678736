/** @format */

// @ts-nocheck

import {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {useSearchParams} from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  startDate: any;
  endDate: any;
  onConfirm: (startDate: Date, endDate: Date) => void;
}

const DateRange = (props: Props) => {
  let [_, setSearchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState([props.startDate, props.endDate]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (startDate && endDate) {
      props.onConfirm(startDate, endDate);
    }
  }, [dateRange]);

  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={update => {
        setDateRange(update);
      }}
      isClearable={true}
      input={<input placeholder='biba' />}
    />
  );
};

export default DateRange;
