/** @format */

import {useState} from 'react';

import {Box, Container, CssBaseline, Drawer, Hidden} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {IsDemoBanner} from 'components/banners/IsDemoBanner';
import {NoDataBanner} from 'components/banners/NoDataBanner';
import PaymentBanner from 'components/banners/PaymentBanner';
import PaymentErrorBanner from 'components/banners/PaymentErrorBanner';
import StratLowConvIssueBanner from 'components/banners/StratLowConvIssueBanner';
import TopMenu from 'components/menus/TopMenu';
import {ACTION_BANNER_Z_INDEX} from 'components/banners/ActionBanner';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useSidebarContext} from 'contexts/SidebarContext';

import isNewVersion from 'helpers/isNewVersionHelper';

import Sidebar from './Sidebar';

const DRAWER_WIDTH = 264;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: DRAWER_WIDTH,
    },
  },
  drawerPaper: {
    backgroundColor: '#3A3A3A',
    width: DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  content: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  // use padding instead of margin because Firefox ignores marginBottom
  container: {
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(22),
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(22),
      paddingTop: theme.spacing(8),
    },
  },
  menu: {
    position: 'relative',
    zIndex: ACTION_BANNER_Z_INDEX + 1,
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
}));

const MainLayout = props => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {currentCompany, currentAdType} = useCompanyContext();
  const {companies} = useSidebarContext();

  const isNewVersionEnabled = isNewVersion();

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderDrawer = () => {
    return (
      <nav className={classes.drawer}>
        <Hidden mdUp>
          <Drawer
            classes={{paper: classes.drawerPaper}}
            open={isDrawerOpen}
            variant='temporary'
            ModalProps={{keepMounted: true}}
            onClose={handleToggleDrawer}
          >
            <Sidebar
              currentAdType={currentAdType}
              currentCompany={currentCompany}
              companies={companies}
              onToggleDrawer={handleToggleDrawer}
            />
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            classes={{paper: classes.drawerPaper}}
            open
            variant='permanent'
          >
            <Sidebar
              currentAdType={currentAdType}
              currentCompany={currentCompany}
              companies={companies}
              onToggleDrawer={handleToggleDrawer}
            />
          </Drawer>
        </Hidden>
      </nav>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {renderDrawer()}

      <Box className={classes.content}>
        <NoDataBanner />
        <PaymentErrorBanner />
        <PaymentBanner />
        <StratLowConvIssueBanner />
        {isNewVersionEnabled && <IsDemoBanner />}

        <Container className={classes.container} maxWidth='md'>
          <Box className={classes.menu}>
            <TopMenu onToggleDrawer={handleToggleDrawer} />
          </Box>

          {props.children}
        </Container>
      </Box>
    </div>
  );
};

export default MainLayout;
