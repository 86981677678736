/** @format */
import {styled} from '@mui/material/styles';
import {Backdrop, Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';

import {ROUTES} from 'constants/ROUTES';

import bgImg from 'images/new_interface_modal_bg.png';

const PREFIX = 'NewInterfaceModal';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  outerContainer: `${PREFIX}-outerContainer`,
  bgImage: `${PREFIX}-bgImage`,
  headerText: `${PREFIX}-headerText`,
  descriptionText: `${PREFIX}-descriptionText`,
  controlsContainer: `${PREFIX}-controlsContainer`,
  controlButton: `${PREFIX}-controlButton`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.backdrop}`]: {
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    zIndex: theme.zIndex.drawer + 1,
  },
  [`& .${classes.outerContainer}`]: {
    width: 478,
    minHeight: 500,
    borderRadius: '32px',
    background:
      'linear-gradient(121deg, rgba(35, 151, 159, 0.00) 8.96%, rgba(35, 151, 159, 0.15) 121.07%), radial-gradient(100% 100% at 50% 100%, rgba(84, 122, 224, 0.04) 0%, rgba(84, 122, 224, 0.40) 100%), #E3E3E3',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'stretch',
      flexGrow: 1,
      borderRadius: '0',
    },
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center',
    },
  },
  [`& .${classes.bgImage}`]: {
    width: '100%',
    height: 'auto',
    borderRadius: '32px',
  },
  [`& .${classes.headerText}`]: {
    padding: theme.spacing(0, 8),
    color: '#202020',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '40px',
  },
  [`& .${classes.descriptionText}`]: {
    padding: theme.spacing(0, 8),
    color: '#202020',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.controlsContainer}`]: {
    padding: theme.spacing(0, 8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  [`& .${classes.controlButton}`]: {
    minWidth: 'auto',
    padding: theme.spacing(4),
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '&:last-of-type': {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

const NewInterfaceModal = () => {
  const navigate = useNavigate();

  const {mixpanel} = useMixpanelContext();

  const handleConfirm = () => {
    localStorage.setItem('isNewVersionEnabled', JSON.stringify(true));
    localStorage.setItem('isNewVersionModalShown', JSON.stringify(true));

    mixpanel.track('click_new_interface_button');
    mixpanel.people.set({interface: 'new'});

    navigate(`/${ROUTES.GROUPING}`);
  };

  const handleDecline = () => {
    localStorage.setItem('isNewVersionModalShown', JSON.stringify(true));

    mixpanel.track('click_old_interface_button');
    mixpanel.people.set({interface: 'old'});

    navigate(0);
  };

  return (
    <Root>
      <Backdrop className={classes.backdrop} open>
        <Box className={classes.outerContainer}>
          <img className={classes.bgImage} src={bgImg} alt='background image' />

          <Box sx={{mt: 8}}>
            <Typography className={classes.headerText}>
              <Trans>Попробуйте новый интерфейс</Trans>
            </Typography>
          </Box>
          <Box sx={{mt: 6}}>
            <Typography className={classes.descriptionText}>
              <Trans>
                Мы сделали новый главный экран, где можно искать и группировать
                свои проекты
              </Trans>
            </Typography>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography className={classes.descriptionText}>
              <Trans>Попробуйте, это удобно 👌</Trans>
            </Typography>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography className={classes.descriptionText}>
              <Trans>Если будет непривычно, переключите обратно</Trans>
            </Typography>
          </Box>
          <Box sx={{mt: 6, mb: 8}} className={classes.controlsContainer}>
            <PrimaryMediumButton
              className={classes.controlButton}
              onClick={handleConfirm}
            >
              <Trans>Включить новый интерфейс</Trans>
            </PrimaryMediumButton>
            <SecondaryMediumButton
              className={classes.controlButton}
              onClick={handleDecline}
            >
              <Trans>Остаться на старом</Trans>
            </SecondaryMediumButton>
          </Box>
        </Box>
      </Backdrop>
    </Root>
  );
};
export default NewInterfaceModal;
