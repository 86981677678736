/** @format */
import {styled} from '@mui/material/styles';
import {Box, Button} from '@mui/material';
import {Trans} from 'react-i18next';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import {useNavigate} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'ChiefReportButton';

const classes = {
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.button}`]: {
    width: '100%',
    borderRadius: '8px',
    background: '#F1F1F1',
    marginBottom: theme.spacing(7),
    padding: theme.spacing(3, 4),
    color: '#202020',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '17px',
  },
  [`& .${classes.buttonIcon}`]: {
    fontSize: '28px',
    color: '#547AE0',
    marginLeft: 'auto',
  },
}));
const ChiefReportButton = () => {
  const navigate = useNavigate();

  const {mixpanel} = useMixpanelContext();

  const handleClick = () => {
    mixpanel.track('open_chief_report_summary_from_interface');

    navigate(`/${ROUTES.CHIEF_REPORT}/${ROUTES.CHIEF_REPORT_SUMMARY}`);
  };

  return (
    <Root>
      <Button className={classes.button} onClick={handleClick}>
        <Trans>Отчет руководителя</Trans>

        <TableChartOutlinedIcon className={classes.buttonIcon} />
      </Button>
    </Root>
  );
};
export default ChiefReportButton;
