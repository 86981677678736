/** @format */
import {useState, useEffect} from 'react';

import {Box, Button, InputAdornment} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import {clsx} from 'clsx';
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';
import {add, format} from 'date-fns';

import LegacyTextField from 'components/inputs/LegacyTextField';
import DateRange from 'components/DateRange';

import {AUTH_TOKEN_KEY} from 'contexts/AuthContext';

import {ROUTES} from 'constants/ROUTES';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'Header';

const classes = {
  container: `${PREFIX}-container`,
  dateControls: `${PREFIX}-dateControls`,
  dateButton: `${PREFIX}-dateButton`,
  dateButtonActive: `${PREFIX}-dateButtonActive`,
  dateButtonIcon: `${PREFIX}-dateButtonIcon`,
  dateButtonIconActive: `${PREFIX}-dateButtonIconActive`,
  tableViewControls: `${PREFIX}-tableViewControls`,
  tableViewButton: `${PREFIX}-tableViewButton`,
  tableViewButtonActive: `${PREFIX}-tableViewButtonActive`,
  searchWrapper: `${PREFIX}-searchWrapper`,
  searchInput: `${PREFIX}-searchInput`,
  downloadButton: `${PREFIX}-downloadButton`,
  downloadButtonIcon: `${PREFIX}-downloadButtonIcon`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(4.5),
  },
  [`& .${classes.dateControls}`]: {
    maxHeight: '48px',
    borderRadius: theme.spacing(2),
    background: '#202020',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
      '& > *': {
        width: '50%',
      },
    },
  },
  [`& .${classes.tableViewControls}`]: {
    background: '#202020',
    borderRadius: theme.spacing(2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
      '& > *': {
        width: '50%',
      },
    },
  },
  [`& .${classes.dateButton}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '143%',
    padding: theme.spacing(3.5, 4),
    letterSpacing: '0.25px',
    '&:first-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:nth-of-type(2)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  [`& .${classes.dateButtonActive}`]: {
    color: '#F1F1F1',
    background: '#2B2B2B',
  },
  [`& .${classes.dateButtonIcon}`]: {
    fontSize: '16px',
    color: '#8B8B8B',
    marginRight: theme.spacing(2.5),
  },
  [`& .${classes.dateButtonIconActive}`]: {
    color: '#F1F1F1',
  },

  [`& .${classes.tableViewButton}`]: {
    padding: theme.spacing(3.5, 4),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: theme.spacing(2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '&:first-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:nth-of-type(2)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  [`& .${classes.tableViewButtonActive}`]: {
    borderBottom: '1px solid #749F23',
    background: '#383838',
  },
  [`& .${classes.searchWrapper}`]: {
    width: '350px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4),
      width: '100%',
      marginRight: 0,
    },
  },
  [`& .${classes.searchInput}`]: {
    '& .MuiInputBase-root': {
      height: '48px',
    },
  },
  [`& .${classes.downloadButton}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3.5, 4),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: theme.spacing(2),
    background: '#202020',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  [`& .${classes.downloadButtonIcon}`]: {
    width: '41px',
    height: 'auto',
    marginLeft: theme.spacing(2),
  },
}));

type ActivePeriod = 'month' | 'custom';

const Header = () => {
  const location = useLocation();

  const {mixpanel} = useMixpanelContext();

  const {t} = useTranslation();

  const navigate = useNavigate();

  const now = new Date();

  const authToken = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
  const token = authToken && authToken.token;

  let [_, setSearchParams] = useSearchParams();

  const [isSummaryPage, setIsSummaryPage] = useState<boolean>(true);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [activePeriod, setActivePeriod] = useState<ActivePeriod>('month');

  useEffect(() => {
    if (startDate && endDate) {
      setSearchParams({
        periodRange: `${startDate.toISOString()}, ${endDate.toISOString()}`,
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setIsSummaryPage(location.pathname.includes(ROUTES.CHIEF_REPORT_SUMMARY));
  }, [location]);

  useEffect(() => {
    setSearchParams({searchQuery: searchQuery});
  }, [searchQuery]);

  const handleChangePeriod = (period: ActivePeriod) => {
    setActivePeriod(period);
  };

  const handleChangeTableView = () => {
    mixpanel.track(
      `click_on_chief_report_${isSummaryPage ? 'summary' : 'staff'}`,
    );

    if (isSummaryPage) {
      navigate(`/${ROUTES.CHIEF_REPORT}/${ROUTES.CHIEF_REPORT_STAFF}`, {
        state: {background: location},
      });
    } else {
      navigate(`/${ROUTES.CHIEF_REPORT}/${ROUTES.CHIEF_REPORT_SUMMARY}`, {
        state: {background: location},
      });
    }

    setActivePeriod('month');
  };
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleDownloadReportView = async () => {
    mixpanel.track(
      `click_on_chief_report_download_button_from_${isSummaryPage ? 'summary' : 'staff'}`,
    );

    const from = format(startDate || add(now, {days: -31}), 'yyyy-MM-dd');
    const to = format(endDate || now, 'yyyy-MM-dd');
    const fetchUrl = `${import.meta.env.VITE_API_URI}/export/${isSummaryPage ? 'sensor_metrics' : 'report_downloads'}?date_from=${from}&date_to=${to}`;

    await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    }).then(async data => {
      if (data.body) {
        const res = await data.blob();

        const link = document.createElement('a');
        document.body.appendChild(link);

        const url = window.URL.createObjectURL(res);
        link.href = url;
        link.download = `chief-report-${from}-${to}.xlsx`;
        link.click();

        window.URL.revokeObjectURL(url);
      }
    });
  };

  const handleDateRangeConfirm = (startDate: Date, endDate: Date) => {
    mixpanel.track('confirm_chief_report_date_range');

    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (activePeriod === 'month') {
      setStartDate(add(now, {days: -31}));
      setEndDate(now);
    }
  }, [activePeriod]);

  return (
    <Root>
      <Box className={classes.container}>
        <Box className={classes.dateControls}>
          <Button
            className={clsx(
              classes.dateButton,
              activePeriod === 'month' && classes.dateButtonActive,
            )}
            onClick={() => handleChangePeriod('month')}
          >
            <Trans>За месяц</Trans>
          </Button>
          <Button
            className={clsx(
              classes.dateButton,
              activePeriod === 'custom' && classes.dateButtonActive,
            )}
            onClick={() => {
              handleChangePeriod('custom');
            }}
          >
            <DateRangeIcon
              className={clsx(
                classes.dateButtonIcon,
                activePeriod === 'custom' && classes.dateButtonIconActive,
              )}
            />
            {activePeriod !== 'custom' ? (
              <Trans>Период</Trans>
            ) : (
              <DateRange
                startDate={startDate}
                endDate={endDate}
                onConfirm={handleDateRangeConfirm}
              />
            )}
          </Button>
        </Box>
        <Box className={classes.tableViewControls}>
          <Button
            className={clsx(
              classes.tableViewButton,
              location.pathname.includes('summary') &&
                classes.tableViewButtonActive,
            )}
            onClick={handleChangeTableView}
          >
            <Trans>По проектам</Trans>
          </Button>
          <Button
            className={clsx(
              classes.tableViewButton,
              location.pathname.includes('staff') &&
                classes.tableViewButtonActive,
            )}
            onClick={handleChangeTableView}
          >
            <Trans>По сотрудникам</Trans>
          </Button>
        </Box>
        <Box className={classes.searchWrapper}>
          <LegacyTextField
            placeholder={
              isSummaryPage
                ? t('Поиск по проектам', 'Поиск по проектам')
                : t('Поиск по сотрудникам', 'Поиск по сотрудникам')
            }
            inputProps={{
              'data-testid': 'search-input',
              name: 'search',
              type: 'search',
            }}
            onChange={handleChangeSearch}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon htmlColor='#8B8B8B' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          className={classes.downloadButton}
          onClick={handleDownloadReportView}
        >
          <Trans>Скачать</Trans>
          <img
            className={classes.downloadButtonIcon}
            alt='Excel icon'
            src={new URL('./images/icon_excel.svg', import.meta.url).toString()}
          />
        </Button>
      </Box>
    </Root>
  );
};

export default Header;
