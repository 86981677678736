/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import {Link as RouterLink} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import * as R from 'ramda';
import {Box, IconButton, Typography, InputAdornment, Link} from '@mui/material';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import SearchIcon from '@mui/icons-material/Search';
import {gql, useQuery} from '@apollo/client';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import LegacyTextField from 'components/inputs/LegacyTextField';
import MainLayoutNew from 'layouts/MainLayoutNew';

import adTypeHelpers from 'helpers/adTypeHelpers';

import {useCompanyContext} from 'contexts/CompanyContext';
import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import Row from './Row';
import ChiefReportButton from './ChiefReportButton';

const PREFIX = 'HomeNew';

const classes = {
  headerTitle: `${PREFIX}-headerTitle`,
  projectTitle: `${PREFIX}-projectTitle`,
  editButton: `${PREFIX}-editButton`,
  editButtonIcon: `${PREFIX}-editButtonIcon`,
  addButtonContainer: `${PREFIX}-addButtonContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  searchInput: `${PREFIX}-searchInput`,
  addButton: `${PREFIX}-addButton`,
  addButtonIcon: `${PREFIX}-addButtonIcon`,
  row: `${PREFIX}-row`,
  rowLink: `${PREFIX}-rowLink`,
  companiesList: `${PREFIX}-companiesList`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.headerTitle}`]: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '30px',
  },

  [`& .${classes.projectTitle}`]: {
    fontStyle: 'normal',
    letterSpacing: '0.225px',
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
  },

  [`& .${classes.editButton}`]: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    backgroundColor: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.editButtonIcon}`]: {
    fontSize: '16px',
    color: '#fff',
  },

  [`& .${classes.buttonsContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },

  [`& .${classes.searchInput}`]: {
    '& fieldset': {
      borderColor: '#202020',
    },
  },

  [`& .${classes.addButton}`]: {
    minWidth: 'auto',
    height: '50px',
    fontSize: '14px',
    fontWeight: 400,
    boxShadow: 'none',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },

  [`& .${classes.addButtonIcon}`]: {
    fontSize: '18px',
  },

  [`& .${classes.row}`]: {
    flexGrow: 1,
  },

  [`& .${classes.rowLink}`]: {
    '&:hover': {
      textDecoration: 'none',
    },
  },

  [`& .${classes.companiesList}`]: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}));

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      companies {
        id
        uuid
        name
        isLaunched
        lastCheckCollectDate
        lastBudgetScores {
          adType
          value
        }
        lastChecks {
          found
          new
        }
        tokens {
          id
          lastError
          provider
          isValid
        }
      }
    }
  }
`;

const HomeNew = () => {
  const {authToken} = useAuthContext();
  const {mixpanel} = useMixpanelContext();
  const {switchAdType, switchCompany} = useCompanyContext();
  const {t} = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');

  const {data: companiesData} = useQuery(GET_CURRENT_USER, {skip: !authToken});

  const handleClickAddNew = () => {
    mixpanel.track('click_home_new_add_company_button');
  };

  const handleAdClick = (adType, company) => {
    switchAdType(adType);
    switchCompany(company);
  };

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (!companiesData) return;
    if (!companiesData.currentUser) return;

    if (search === '') setCompanies(companiesData.currentUser.companies);

    const lowerCaseSearch = search.toLowerCase();
    const items = R.pipe(
      R.filter(v => v.name.toLowerCase().indexOf(lowerCaseSearch) >= 0),
      R.sortBy(v => v.name),
    )(companiesData.currentUser.companies);

    setCompanies(items);
  }, [companiesData, search]);

  return (
    <Root>
      <MainLayoutNew>
        <Box
          sx={{
            mb: 10,
          }}
        >
          <Box>
            <Box
              sx={{
                mt: 12,
              }}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              flexWrap={'wrap'}
              spacing={4}
            >
              <Typography className={classes.headerTitle}>
                <Trans>Проекты</Trans>
              </Typography>
              <Box className={classes.buttonsContainer}>
                <ChiefReportButton />

                <PrimaryMediumButton
                  component={RouterLink}
                  to={{pathname: '/home/settings/wizard/company'}}
                  variant='contained'
                  onClick={handleClickAddNew}
                  className={classes.addButton}
                >
                  <Box mr={2} display='flex' alignItems='center'>
                    <AddCircleOutlineRounded
                      className={classes.addButtonIcon}
                    />
                  </Box>
                  <Trans>Добавить проект</Trans>
                </PrimaryMediumButton>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 6,
            }}
          >
            <LegacyTextField
              placeholder={t('Поиск по проектам', 'Поиск по проектам')}
              inputProps={{
                'data-testid': 'search-input',
                name: 'search',
                type: 'search',
              }}
              onChange={handleChangeSearch}
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon htmlColor='#8B8B8B' />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <ul className={classes.companiesList}>
            {companies &&
              companies.map(v => {
                const isContextConnected = adTypeHelpers.isAdTypeConnected(
                  v.tokens,
                  'CONTEXT',
                );
                const isTargetConnected = adTypeHelpers.isAdTypeConnected(
                  v.tokens,
                  'TARGET',
                );
                const areNoTokens = v.tokens.length === 0;

                const isNoAccessToContextAccount = adTypeHelpers
                  .filterAdTypeFromPlatforms(v.tokens, 'CONTEXT')
                  .some(token => !token.isValid);
                const isNoAccessToTargetAccount = adTypeHelpers
                  .filterAdTypeFromPlatforms(v.tokens, 'TARGET')
                  .some(token => !token.isValid);

                const {recomendations, newErrors} = v.lastChecks.reduce(
                  (acc, cur) => ({
                    recomendations: acc.recomendations + cur.found,
                    newErrors: acc.newErrors + cur.new,
                  }),
                  {
                    recomendations: 0,
                    newErrors: 0,
                  },
                );
                const contextBudgetScore = v.lastBudgetScores.find(
                  v => v.adType === 'CONTEXT',
                ).value;
                const targetBudgetScore = v.lastBudgetScores.find(
                  v => v.adType === 'TARGET',
                ).value;

                return (
                  <li key={v.uuid} data-testid='companies-list-item'>
                    <Box
                      sx={{
                        mt: 10,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography className={classes.projectTitle}>
                          {v.name}
                        </Typography>
                        <IconButton
                          className={classes.editButton}
                          size='small'
                          component={RouterLink}
                          to={`/home/settings/connect/company?companyId=${v.id}`}
                        >
                          <CreateOutlined className={classes.editButtonIcon} />
                        </IconButton>
                      </Box>
                      {isContextConnected && (
                        <Box
                          sx={{
                            mt: 6,
                          }}
                        >
                          <Link
                            to={'/home/main/status'}
                            component={RouterLink}
                            className={classes.rowLink}
                            onClick={() => handleAdClick('CONTEXT', v)}
                            data-testid='row-link'
                          >
                            <Row
                              adType='CONTEXT'
                              isLaunched={v.isLaunched}
                              lastCheckCollectDate={v.lastCheckCollectDate}
                              isNoAccessToAccount={isNoAccessToContextAccount}
                              areNoTokens={areNoTokens}
                              budgetScore={contextBudgetScore}
                              recomendations={recomendations}
                              newErrors={newErrors}
                            />
                          </Link>
                        </Box>
                      )}
                      {isTargetConnected && (
                        <Box
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Link
                            to={'/home/main/status'}
                            component={RouterLink}
                            className={classes.rowLink}
                            onClick={() => handleAdClick('TARGET', v)}
                            data-testid='row-link'
                          >
                            <Row
                              adType='TARGET'
                              budgetScore={targetBudgetScore}
                              lastCheckCollectDate={v.lastCheckCollectDate}
                              isNoAccessToAccount={isNoAccessToTargetAccount}
                              areNoTokens={areNoTokens}
                            />
                          </Link>
                        </Box>
                      )}
                      {areNoTokens && (
                        <Box
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Link
                            to={'/home/main/status'}
                            component={RouterLink}
                            className={classes.rowLink}
                            onClick={() => handleAdClick('TARGET', v)}
                            data-testid='row-link'
                          >
                            <Row
                              adType='TARGET'
                              lastCheckCollectDate={v.lastCheckCollectDate}
                              areNoTokens={areNoTokens}
                              recomendations={recomendations}
                              newErrors={newErrors}
                            />
                          </Link>
                        </Box>
                      )}
                    </Box>
                  </li>
                );
              })}
          </ul>
        </Box>
      </MainLayoutNew>
    </Root>
  );
};

export default HomeNew;
