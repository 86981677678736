/** @format */

import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import isNewVersion from 'helpers/isNewVersionHelper';

import GreenRadio from 'components/radios/GreenRadio';

interface RadioRowProps {
  text: string;
  selected: boolean;
  mt?: number;
  onSelect: () => void;
  children?: React.ReactChild;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    children: {
      marginLeft: 0,
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(7),
      },
    },
    childrenNew: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
  }),
);

const RadioRow = (props: RadioRowProps) => {
  const classes = useStyles();

  const isNewVersionEnabled = isNewVersion();

  return isNewVersionEnabled ? (
    <Box
      alignItems='center'
      display='flex'
      flexGrow={1}
      flexWrap='wrap'
      justifyContent='flex-start'
      mt={props.mt}
    >
      <Box display='flex' alignItems='center' onClick={props.onSelect}>
        <GreenRadio checked={props.selected} variant='SQUARE' />
      </Box>
      <Box className={classes.childrenNew}>{props.children}</Box>
    </Box>
  ) : (
    <Box
      alignItems='center'
      display='flex'
      flexGrow={1}
      flexWrap='wrap'
      justifyContent='flex-start'
      mt={props.mt}
    >
      <Box display='flex' alignItems='center' onClick={props.onSelect}>
        <GreenRadio
          checked={props.selected}
          iconVariant='check'
          variant='LIGHT'
        />
        <Box ml={4}>
          <Typography align='left' className={classes.text}>
            <Trans>{props.text}</Trans>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.children}>{props.children}</Box>
    </Box>
  );
};

export default RadioRow;
