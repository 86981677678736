/** @format */

import {useState, useEffect, useMemo} from 'react';

import {Box, LinearProgress} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import {add, format} from 'date-fns';

import Table from './Table';
import TableHeader from './Table/TableHeader';
import TableBody from './Table/TableBody';
import TableRow from './Table/TableRow';

import {type TABLE_DIRECTION} from './Table/TableHeader';

const PROJECT_ID = 1;
const STAFF_ID = 2;
const SENSORS_ID = 3;
const TRIGGERED_ID = 4;
const FIXED_ID = 5;
export const RESOLVED_AVG_ID = 6;
export const RESOLVED_MIN_ID = 7;
export const RESOLVED_MAX_ID = 8;

const headerRows = [
  {
    id: PROJECT_ID,
    title: 'Проект',
    isSortable: true,
  },
  {
    id: STAFF_ID,
    title: 'Сотрудники',
    isSortable: true,
  },
  {
    id: SENSORS_ID,
    title: 'Сработавшие сенсоры',
    isWide: true,
    isSortable: true,
  },
  {
    id: TRIGGERED_ID,
    title: 'Срабатывания',
    isSortable: true,
  },
  {
    id: FIXED_ID,
    title: 'Исправления',
    isSortable: true,
  },
  {
    id: RESOLVED_AVG_ID,
    title: 'Среднее',
    description:
      'Среднее время исправления срабатываний по сенсорам с момента возникновения (в днях)',
    isSortable: true,
  },
  {
    id: RESOLVED_MIN_ID,
    title: 'Мин',
    description:
      'Минимальное время исправления срабатываний по сенсорам с момента возникновения (в днях)',
    isSortable: true,
  },
  {
    id: RESOLVED_MAX_ID,
    title: 'Макс',
    description:
      'Максимальное время исправления срабатываний по сенсорам с момента возникновения (в днях)',
    isSortable: true,
  },
];

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      companySensorMetrics(startDate: $startDate, endDate: $endDate) {
        company {
          id
          uuid
          name
        }
        connectedUsers {
          uuid
          profile {
            name
          }
        }
        sensors {
          name
          fixed
          found
          resolvedAvg
          resolvedMax
          resolvedMin
          hasExceptions
          isDisabled
        }
      }
    }
  }
`;

const Summary = () => {
  let [searchParams] = useSearchParams();

  const now = new Date();

  const searchQuery = searchParams.get('searchQuery');

  const [expandedRows, setExpandedRows] = useState<[]>([]);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const [sortedId, setSortedId] = useState<number>(PROJECT_ID);
  const [tableSortDirection, setTableSortDirection] =
    useState<TABLE_DIRECTION>('desc');
  const sortedValue = tableSortDirection === 'asc' ? -1 : 1;

  const {loading, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate || add(now, {days: -31}), 'yyyy-MM-dd'),
      endDate: format(endDate || now, 'yyyy-MM-dd'),
    },
  });

  useEffect(() => {
    const periodRange = searchParams.get('periodRange')?.split(', ');

    if (periodRange) {
      setStartDate(new Date(periodRange[0]));
      setEndDate(new Date(periodRange[1]));

      refetch();
    }
  }, [searchParams.get('periodRange')]);

  const handleSort = (id: number, direction: TABLE_DIRECTION) => {
    setSortedId(id);
    setTableSortDirection(direction);
  };

  const sortedData = useMemo(() => {
    if (!data) return [];

    const {companySensorMetrics} = data.currentUser;
    const result = [...companySensorMetrics];

    if (sortedId === PROJECT_ID) {
      result.sort((a, b) =>
        a.company.name.toLowerCase() < b.company.name.toLowerCase()
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === STAFF_ID) {
      result.sort((a, b) =>
        a.connectedUsers.length < b.connectedUsers.length
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === SENSORS_ID) {
      result.sort((a, b) =>
        a.sensors.length < b.sensors.length ? sortedValue : -1 * sortedValue,
      );
    }

    if (sortedId === TRIGGERED_ID) {
      const foundValue = sensors =>
        sensors.reduce((acc, cur) => {
          return acc + cur.found;
        }, 0);

      result.sort((a, b) =>
        foundValue(a.sensors) < foundValue(b.sensors)
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === FIXED_ID) {
      const fixedValue = sensors =>
        sensors.reduce((acc, cur) => {
          return acc + cur.fixed;
        }, 0);

      result.sort((a, b) =>
        fixedValue(a.sensors) < fixedValue(b.sensors)
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === RESOLVED_AVG_ID) {
      const resolvedAvgValue = data => {
        if (!data.sensors.length) return 0;

        return (
          data.sensors.reduce((acc, cur) => {
            return acc + cur.resolvedAvg;
          }, 0) / data.sensors.length
        );
      };

      result.sort((a, b) =>
        resolvedAvgValue(a) < resolvedAvgValue(b)
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === RESOLVED_MIN_ID) {
      const resolvedMinValue = data => {
        if (!data.sensors.length) return 0;

        const allResolvedMin = data.sensors
          .filter(sensor => !sensor.isDisabled)
          .map(sensor => sensor.resolvedMin);

        return Math.max(...allResolvedMin);
      };

      result.sort((a, b) =>
        resolvedMinValue(a) < resolvedMinValue(b)
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === RESOLVED_MAX_ID) {
      const resolvedMaxValue = data => {
        if (!data.sensors.length) return 0;

        const allResolvedMax = data.sensors
          .filter(sensor => !sensor.isDisabled)
          .map(sensor => sensor.resolvedMax);

        return Math.max(...allResolvedMax);
      };

      result.sort((a, b) =>
        resolvedMaxValue(a) < resolvedMaxValue(b)
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    return result;
  }, [sortedId, sortedValue, data]);

  const filteredData = useMemo(() => {
    const result = [...sortedData];

    if (searchQuery) {
      return result.filter(data =>
        data.company.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    return result;
  }, [sortedData, searchQuery]);

  if (loading) {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LinearProgress />
      </Box>
    );
  }

  const handleRowExpand = (id, data) => {
    if (!data.sensors.length && data.connectedUsers.length < 2) return;

    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter(rowId => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleExpandAll = () => {
    if (expandedRows.length) {
      setExpandedRows([]);
    } else {
      setExpandedRows(tableData.map(row => row.company.id));
    }
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Table type='summary'>
          <TableHeader
            type='summary'
            headerData={headerRows}
            handleExpandAll={handleExpandAll}
            isExpanded={Boolean(expandedRows.length)}
            isExpandable
            handleSort={handleSort}
            sortedId={sortedId}
            sortDirection={tableSortDirection}
          />

          <TableBody>
            {filteredData.map((data, index) => (
              <TableRow
                key={index}
                type='summary'
                rowData={data}
                onClick={() => handleRowExpand(data.company.id, data)}
                isExpanded={expandedRows.includes(data.company.id)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Summary;
