/** @format */
import {useState, useEffect} from 'react';
import {clsx} from 'clsx';
import {Box, Typography, Button, useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useTheme} from '@mui/material/styles';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import Breadcrumbs from 'components/Breadcrumbs';
import BackHeader from 'components/BackHeader';

import isNewVersion from 'helpers/isNewVersionHelper';

import {ROUTES} from 'constants/ROUTES';

import oldVersionInterface from 'images/old_interface.png';
import newVersionInterface from 'images/new_interface.png';

const PREFIX = 'Interface';

const classes = {
  headerTitle: `${PREFIX}-headerTitle`,
  pageDescription: `${PREFIX}-pageDescription`,
  versionPicture: `${PREFIX}-versionPicture`,
  versionPictureMobile: `${PREFIX}-versionPictureMobile`,
  versionPictureActive: `${PREFIX}-versionPictureActive`,
  versionButton: `${PREFIX}-versionButton`,
  versionButtonActive: `${PREFIX}-versionButtonActive`,
  checkIcon: `${PREFIX}-checkIcon`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.headerTitle}`]: {
    color: '#F1F1F1',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.24px',
  },
  [`& .${classes.pageDescription}`]: {
    maxWidth: '600px',
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.versionButton}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.versionButtonActive}`]: {
    background: '#383838',
  },
  [`& .${classes.checkIcon}`]: {
    marginLeft: theme.spacing(4),
    color: '#749F23',
    fontSize: '16px',
  },
  [`& .${classes.versionPicture}`]: {
    width: '100%',
    height: 'auto',
    marginTop: theme.spacing(8),
    opacity: 0.2,
  },
  [`& .${classes.versionPictureMobile}`]: {
    width: '100%',
    height: 'auto',
    marginTop: theme.spacing(8),
  },
  [`& .${classes.versionPictureActive}`]: {
    opacity: 1,
  },
}));

const breadcrumbs = [
  {
    href: ROUTES.SETTINGS,
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '',
    title: <Trans>Режим интерфейса</Trans>,
  },
];

const Interface = () => {
  const {mixpanel} = useMixpanelContext();

  const isNewVersionEnabled = isNewVersion();

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isNewVersionState, setIsNewVersionState] =
    useState(isNewVersionEnabled);

  const handleVersionChange = () => {
    setIsNewVersionState(!isNewVersionState);

    if (isNewVersionState) {
      mixpanel.track('click_new_interface_button');
      mixpanel.people.set({interface: 'new'});
    } else {
      mixpanel.track('click_old_interface_button');
      mixpanel.people.set({interface: 'old'});
    }

    navigate(0);
  };

  useEffect(() => {
    localStorage.setItem(
      'isNewVersionEnabled',
      JSON.stringify(isNewVersionState),
    );
  }, [isNewVersionState]);

  return (
    <Root>
      <Box
        sx={{
          mb: 3,
        }}
      >
        {isNewVersionEnabled ? (
          <Breadcrumbs items={breadcrumbs} />
        ) : (
          <BackHeader />
        )}
      </Box>
      <Box
        sx={{
          mt: 10,
        }}
      >
        <Typography className={classes.headerTitle}>
          <Trans>Режим интерфейса</Trans>
        </Typography>
        <Box sx={{mt: 8}}>
          <Typography className={classes.pageDescription}>
            <Trans>В будущем старая версия интерфейса будет отключена</Trans>
          </Typography>
        </Box>
        <Box sx={{mt: 2}}>
          <Typography className={classes.pageDescription}>
            <Trans>
              Новая версия будет развиваться, но уже сейчас в ней доступны поиск
              по проектам и их группировка
            </Trans>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button
            className={clsx(
              classes.versionButton,
              !isNewVersionState && classes.versionButtonActive,
            )}
            onClick={handleVersionChange}
          >
            <Trans>Старая версия</Trans>
            {!isNewVersionState && (
              <CheckCircleIcon className={classes.checkIcon} />
            )}
          </Button>
          {!isMobile && (
            <img
              className={clsx(
                classes.versionPicture,
                !isNewVersionState && classes.versionPictureActive,
              )}
              src={oldVersionInterface}
              alt='interface picture'
            />
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button
            className={clsx(
              classes.versionButton,
              isNewVersionState && classes.versionButtonActive,
            )}
            onClick={handleVersionChange}
          >
            <Trans>Новая версия</Trans>
            {isNewVersionState && (
              <CheckCircleIcon className={classes.checkIcon} />
            )}
          </Button>
          {!isMobile && (
            <img
              className={clsx(
                classes.versionPicture,
                isNewVersionState && classes.versionPictureActive,
              )}
              src={newVersionInterface}
              alt='interface picture'
            />
          )}
        </Box>
      </Box>
      {isMobile && (
        <img
          className={clsx(classes.versionPictureMobile)}
          src={isNewVersionState ? newVersionInterface : oldVersionInterface}
          alt='interface picture'
        />
      )}
    </Root>
  );
};
export default Interface;
