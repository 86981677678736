/** @format */

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';
import {Box, IconButton, LinearProgress, Link, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate, useLocation} from 'react-router-dom';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LinkIcon from '@mui/icons-material/Link';

import isNewVersion from 'helpers/isNewVersionHelper';

import Breadcrumbs from 'components/Breadcrumbs';
import BackHeader from 'components/BackHeader';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import NotFoundMessage from 'components/NotFoundMessage';
import routerHelpers from 'helpers/routerHelpers';

import Row from './Row';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($specialistId: ID!) {
    currentUser {
      specialist(id: $specialistId) {
        id
        name
        email
        uuid
        notifications {
          id
          channelType
          adTypes
          emailChannel {
            email
          }
          telegramChannel {
            xeniooUserUuid
          }
          company {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_SPECIALIST = gql`
  mutation DeleteSpecialist($id: ID!) {
    deleteSpecialist(id: $id)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  link: {
    fontSize: 14,
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },
  hint: {
    color: '#8C8D8D',
    fontSize: 14,
  },
  specialistTitle: {
    color: '#F1F1F1',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.169px',
  },
  addNotificationButton: {
    width: '100%',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  row: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#202020',
    '&:last-of-type': {
      marginTop: 0,
    },
  },
  tgBotTitle: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.131px',
  },
  hintNew: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.131px',
  },
  linkNew: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 8),
    borderRadius: '8px',
    background: '#2B2B2B',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(4),
      justifyContent: 'center',
    },
  },
  linkIcon: {
    marginRight: theme.spacing(2),
  },
  actionButtonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(6),
    },
  },
  actionButton: {
    width: '48px',
    height: '48px',
    marginRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    background: '#202020',
    '&:last-of-type': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: '1',
    },
  },
}));

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '/home/settings/specialists',
    title: <Trans>Уведомления</Trans>,
  },
  {
    href: '',
    title: <Trans>Управление</Trans>,
  },
];

export const NotificationList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = routerHelpers.useSearchParams();
  const specialistId = searchParams.get('specialistId');
  const hideInfo = searchParams.get('hideInfo');

  const isNewVersionEnabled = isNewVersion();

  const classes = useStyles();

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {specialistId},
  });
  const [deleteSpecialist, {loading: deleteSpecialistLoading}] =
    useMutation(DELETE_SPECIALIST);
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);

  routerHelpers.useCallbackOnReturn(refetch);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      if (error.graphQLErrors[0].extensions?.code === 'NOT_FOUND') {
        return <NotFoundMessage />;
      }
    }
    return <Alert severity='error'>{error.message}</Alert>;
  }

  const {specialist} = data.currentUser;

  const sortedNotifications = R.sortBy(
    R.path(['company', 'name']),
    specialist.notifications,
  );

  const handleCreate = () => {
    navigate({
      pathname: '/home/settings/notification',
      search: `?specialistId=${specialistId}`,
    });
  };

  const handleEditSpecialist = () => {
    navigate(
      {
        pathname: '/home/settings/specialist',
        search: `?id=${specialistId}`,
      },
      {state: {background: location}},
    );
  };

  const handleDeleteSpecialist = async () => {
    await deleteSpecialist({variables: {id: specialist.id}});
    navigate('/home/settings/specialists', {replace: true});
  };

  const botLink = `http://t.me/adsensor_notifications_bot?start=${specialist.uuid}`;

  const handleEditNotification = id => {
    navigate(
      `/home/settings/notification?specialistId=${specialistId}&id=${id}`,
    );
  };

  const handleDeleteNotification = async id => {
    await deleteNotification({variables: {id}});
    refetch();
  };

  return isNewVersionEnabled ? (
    <>
      <Box mb={10}>
        <Breadcrumbs items={breadcrumbs} />
      </Box>

      {hideInfo === null && (
        <Box display='flex' justifyContent='space-between' flexWrap={'wrap'}>
          <Box>
            <Typography className={classes.specialistTitle}>
              {specialist.name}
            </Typography>

            <Box display='flex' flexDirection='column' mt={3}>
              <Typography variant='body2'>{specialist.email}</Typography>
            </Box>
          </Box>
          <Box className={classes.actionButtonsContainer}>
            <IconButton
              className={classes.actionButton}
              disabled={deleteSpecialistLoading}
              size='small'
              aria-label='edit-button'
              onClick={handleEditSpecialist}
            >
              <EditOutlinedIcon htmlColor='#fff' fontSize='small' />
            </IconButton>

            <IconButton
              className={classes.actionButton}
              disabled={deleteSpecialistLoading}
              size='small'
              aria-label='delete-button'
              onClick={handleDeleteSpecialist}
            >
              <DeleteForeverOutlinedIcon htmlColor='#fff' fontSize='small' />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box
        className={classes.row}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box display='flex' flexDirection='column'>
          <Box>
            <Typography className={classes.tgBotTitle}>
              <Trans>Телеграм бот</Trans>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.hintNew}>
              <Trans>
                Вы можете скопировать и отправить эту ссылку специалисту,
                который будет получать уведомления
              </Trans>
            </Typography>
          </Box>
        </Box>
        <Link className={classes.linkNew} href={botLink} target='_blank'>
          <LinkIcon
            className={classes.linkIcon}
            fontSize='small'
            htmlColor='#fff'
          />
          <Trans>Ссылка на бота</Trans>
        </Link>
      </Box>

      <Box display='flex' flexDirection='column' mt={3}>
        {sortedNotifications.map((v, i) => (
          <Row
            key={i}
            notification={v}
            onEdit={() => handleEditNotification(v.id)}
            onDelete={() => handleDeleteNotification(v.id)}
          />
        ))}
      </Box>

      <Box mt={5}>
        <PrimaryMediumButton
          className={classes.addNotificationButton}
          onClick={handleCreate}
        >
          <Box mr={2} display='flex' alignItems='center'>
            <AddCircleOutlineRounded fontSize='small' />
          </Box>
          <Trans>Добавить уведомление</Trans>
        </PrimaryMediumButton>
      </Box>
    </>
  ) : (
    <>
      <Box mb={3}>
        <BackHeader />
      </Box>

      {hideInfo === null && (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h1'>{specialist.name}</Typography>
          <Box>
            <IconButton
              disabled={deleteSpecialistLoading}
              size='small'
              aria-label='edit-button'
              onClick={handleEditSpecialist}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={deleteSpecialistLoading}
              size='small'
              aria-label='delete-button'
              onClick={handleDeleteSpecialist}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box display='flex' flexDirection='column' mt={3}>
        <Typography variant='body1'>{specialist.email}</Typography>
      </Box>

      <Box display='flex' flexDirection='column' mt={3}>
        <Typography variant='body1'>
          <Trans>
            Телеграм бот{' '}
            <Link
              className={clsx(classes.body, classes.link)}
              href={botLink}
              target='_blank'
            >
              ссылка
            </Link>
          </Trans>
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant='body2' className={classes.hint}>
          <Trans>
            Вы можете скопировать и отправить эту ссылку специалисту, который
            будет получать уведомления
          </Trans>
        </Typography>
      </Box>

      <Box display='flex' flexDirection='column' mt={3}>
        {sortedNotifications.map((v, i) => (
          <Row
            key={i}
            notification={v}
            onEdit={() => handleEditNotification(v.id)}
            onDelete={() => handleDeleteNotification(v.id)}
          />
        ))}
      </Box>

      <Box mt={5}>
        <PrimaryMediumButton onClick={handleCreate}>
          <Trans>Добавить уведомление</Trans>
        </PrimaryMediumButton>
      </Box>
    </>
  );
};
