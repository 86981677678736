/** @format */

import {useEffect, useState} from 'react';

import Alert from '@mui/material/Alert';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate, useLocation} from 'react-router-dom';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';

import isNewVersion from 'helpers/isNewVersionHelper';

import Breadcrumbs from 'components/Breadcrumbs';
import BackHeader from 'components/BackHeader';
import ConnectRows from 'components/connect/ConnectRows';
import InviteGuest from 'pages/Wizard/Connect/InviteGuest';
import MediumTextField from 'components/inputs/MediumTextField';
import LegacyTextField from 'components/inputs/LegacyTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import TertiaryMediumButton from 'components/buttons/TertiaryMediumButton';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import adTypeHelpers from 'helpers/adTypeHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useSidebarContext} from 'contexts/SidebarContext';

const useStyles = makeStyles(theme => ({
  link: theme.typography.link,
  companyTitle: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  companyButton: {
    width: '48px',
    height: '48px',
    marginRight: '16px',
    borderRadius: '8px',
    background: '#202020',
    '&:last-of-type': {
      marginRight: '0',
    },
  },
  companyTitleInput: {
    width: '100%',
  },
  saveCompanyTitleButton: {
    height: '56px',
  },
  row: {
    padding: '16px',
    borderRadius: '8px',
    background: '#202020',
  },
  inviteGuestTitle: {
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '0.131px',
  },
  inviteGuestDescription: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.131px',
  },
  inviteGuestButton: {
    display: 'flex',
    alignItems: 'center',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '16px',
    },
  },
  inviteGuestIcon: {
    marginRight: '8px',
  },
}));

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID) {
    currentUser {
      type
      isNew
      isSubscriptionLimitExceeded
      roles
      company(id: $companyId) {
        id
        uuid
        name
        tokens {
          lastError
          provider
          accounts {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $name: String!) {
    updateCompany(id: $id, name: $name) {
      id
    }
  }
`;

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '/home/settings/connect',
    title: <Trans>Проекты</Trans>,
  },
  {
    href: '',
    title: <Trans>Управление</Trans>,
  },
];

export const Company = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {t} = useTranslation();

  const isNewVersionEnabled = isNewVersion();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');
  const {mixpanel} = useMixpanelContext();
  const {refreshSidebar} = useSidebarContext();

  const classes = useStyles();

  const [state, setState] = useState({name: ''});
  const [editMode, setEditMode] = useState(false);
  const [saveCompanyError, setSaveCompanyError] = useState('');
  const [showInviteGuest, setShowInviteGuest] = useState(false);

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {companyId},
  });
  const [updateCompany, {loading: updateProcessing}] =
    useMutation(UPDATE_COMPANY);

  routerHelpers.useCallbackOnReturn(refetch);

  useEffect(() => {
    if (!data) return;

    const {company} = data.currentUser;
    if (!company) return;

    setState({name: company.name});
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {
    type: userType,
    isNew,
    isSubscriptionLimitExceeded,
    company,
    roles,
  } = data.currentUser;
  const platforms = () => {
    if (routerHelpers.IsRuVersion()) {
      return adTypeHelpers.ALL_PLATFORMS;
    }
    return adTypeHelpers.rejectRuPlatforms(adTypeHelpers.ALL_PLATFORMS);
  };

  const renderForm = () => {
    const handleChange = event => {
      const {name, value} = event.target;
      setState({...state, [name]: value});
    };

    if (isNewVersionEnabled) {
      return (
        <LegacyTextField
          className={classes.companyTitleInput}
          error={Boolean(saveCompanyError)}
          placeholder={t('Название проекта', 'Название проекта')}
          value={state.name}
          inputProps={{name: 'name'}}
          myProps={{placeholderFocused: ''}}
          onChange={handleChange}
        />
      );
    }

    return (
      <MediumTextField
        error={Boolean(saveCompanyError)}
        placeholder={t('Название проекта', 'Название проекта')}
        value={state.name}
        inputProps={{name: 'name'}}
        myProps={{placeholderFocused: ''}}
        onChange={handleChange}
      />
    );
  };

  const handleSave = async () => {
    try {
      mixpanel.track('click_settings_save_company_button', {
        company_id: company.id,
        company_uuid: company.uuid,
        company_name: company.name,
      });

      await updateCompany({
        variables: {id: company.id, name: state.name},
      });

      refreshSidebar();
      setEditMode(false);
    } catch (e) {
      setSaveCompanyError(e.message);
    }
  };

  const handleEdit = () => {
    mixpanel.track('click_settings_edit_company_button', {
      company_id: company.id,
      company_uuid: company.uuid,
      company_name: company.name,
    });

    setEditMode(true);
  };

  const renderInviteGuest = () => {
    const handleOpen = () => {
      setShowInviteGuest(true);
    };

    const handleClose = () => {
      setShowInviteGuest(false);
    };

    if (isNewVersionEnabled) {
      return (
        <Box
          className={classes.row}
          mt={6}
          display={'flex'}
          flexWrap={'wrap'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography className={classes.inviteGuestTitle}>
              <Trans>Если у вас нет доступов от кабинетов</Trans>
            </Typography>

            <Box mt={2}>
              <Typography className={classes.inviteGuestDescription}>
                <Trans>Отправьте ссылку своему маркетологу</Trans>
              </Typography>
            </Box>
          </Box>

          <PrimaryMediumButton
            className={classes.inviteGuestButton}
            onClick={handleOpen}
          >
            <RecordVoiceOverOutlinedIcon
              className={classes.inviteGuestIcon}
              fontSize='small'
            />

            <Trans>Попросить специалиста выдать доступы</Trans>
          </PrimaryMediumButton>

          {showInviteGuest && <InviteGuest onClose={handleClose} />}
        </Box>
      );
    }

    return (
      <>
        <Box mt={12}>
          <Typography variant='h1'>
            <Trans>Если у вас нет доступов от кабинетов</Trans>
          </Typography>

          <Box mt={4}>
            <Typography variant='body1'>
              <Trans>
                Отправьте специальную страницу своему маркетологу, он выдаст
                доступы, после этого вы сможете продолжить работу.
              </Trans>
            </Typography>
          </Box>

          <Box mt={6}>
            <TertiaryMediumButton onClick={handleOpen}>
              <Trans>Попросить специалиста выдать доступы</Trans>
            </TertiaryMediumButton>
          </Box>
        </Box>

        {showInviteGuest && <InviteGuest onClose={handleClose} />}
      </>
    );
  };

  const handleDelete = async () => {
    mixpanel.track('click_settings_delete_company_button', {
      company_id: company.id,
      company_uuid: company.uuid,
      company_name: company.name,
    });

    navigate(
      `/home/settings/connect/company/confirm-delete-company?companyId=${company.id}`,
      {
        state: {background: location},
      },
    );
  };

  return (
    <Box>
      {isNewVersionEnabled ? (
        <Box>
          <Box mb={10}>
            <Breadcrumbs items={breadcrumbs} />
          </Box>

          {editMode ? (
            <Grid container spacing={4}>
              <Grid item xs={12} md={10}>
                {renderForm()}
              </Grid>
              <Grid
                container
                item
                alignItems='center'
                justifyContent='flex-end'
                xs={12}
                md={2}
              >
                {!updateProcessing && (
                  <TransparentSmallButton
                    className={classes.saveCompanyTitleButton}
                    disabled={updateProcessing}
                    onClick={handleSave}
                  >
                    <Typography className={classes.link}>
                      <Trans>Сохранить</Trans>
                    </Typography>
                  </TransparentSmallButton>
                )}
                {updateProcessing && <CircularProgress />}
              </Grid>
            </Grid>
          ) : (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems={'center'}
            >
              <Typography className={classes.companyTitle}>
                {state.name}
              </Typography>

              <Box>
                <IconButton
                  className={classes.companyButton}
                  aria-label='company-edit-button'
                  onClick={handleEdit}
                  size='large'
                >
                  <CreateOutlinedIcon htmlColor='#fff' />
                </IconButton>
                <IconButton
                  className={classes.companyButton}
                  aria-label='company-delete-button'
                  onClick={handleDelete}
                  size='large'
                >
                  <DeleteForeverOutlinedIcon htmlColor='#fff' />
                </IconButton>
              </Box>
            </Box>
          )}

          {renderInviteGuest()}

          <Box mt={6}>
            <ConnectRows
              canEditLimits
              canSelectSubaccounts
              companyId={company.id}
              needPay={
                !isNew && userType === 'BUSINESS' && isSubscriptionLimitExceeded
              }
              tokens={company.tokens}
              platforms={platforms()}
              showMetaExtremismCaption={routerHelpers.IsRuVersion()}
              userRole={roles}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb={10}>
            <BackHeader backPath='/home/settings/connect' />
          </Box>
          {editMode ? (
            <Grid container spacing={8}>
              <Grid item xs={12} sm={9}>
                {renderForm()}
              </Grid>
              <Grid
                container
                item
                alignItems='center'
                justifyContent='flex-end'
                xs={12}
                sm={3}
              >
                {!updateProcessing && (
                  <TransparentSmallButton
                    disabled={updateProcessing}
                    onClick={handleSave}
                  >
                    <Typography className={classes.link}>
                      <Trans>Сохранить</Trans>
                    </Typography>
                  </TransparentSmallButton>
                )}
                {updateProcessing && <CircularProgress />}
              </Grid>
            </Grid>
          ) : (
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h1'>{state.name}</Typography>
              <Box>
                <IconButton
                  aria-label='company-edit-button'
                  onClick={handleEdit}
                  size='large'
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label='company-delete-button'
                  onClick={handleDelete}
                  size='large'
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box mt={6}>
            <ConnectRows
              canEditLimits
              canSelectSubaccounts
              companyId={company.id}
              needPay={
                !isNew && userType === 'BUSINESS' && isSubscriptionLimitExceeded
              }
              tokens={company.tokens}
              platforms={platforms()}
              showMetaExtremismCaption={routerHelpers.IsRuVersion()}
              userRole={roles}
            />
          </Box>
          {renderInviteGuest()}
        </Box>
      )}
    </Box>
  );
};
