/** @format */

import {useState} from 'react';

import {styled} from '@mui/material/styles';

import {
  Box,
  Container,
  CssBaseline,
  Link,
  IconButton,
  Drawer,
  Hidden,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Header from 'pages/ChiefReport/Header';
import TopMenuNew from 'components/menus/TopMenuNew';
import {ACTION_BANNER_Z_INDEX} from 'components/banners/ActionBanner';

import logoImg from 'images/logo_mobile.png';

const PREFIX = 'ChiefReportLayout';

const classes = {
  root: `${PREFIX}-root`,
  menuMobile: `${PREFIX}-menuMobile`,
  menuMobileExpandButton: `${PREFIX}-menuMobileExpandButton`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  menu: `${PREFIX}-menu`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.menuMobile}`]: {
    padding: theme.spacing(4, 3),
    backgroundColor: theme.palette.action.disabledBackground,
  },

  [`& .${classes.menuMobileExpandButton}`]: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#2B2B2B',
  },

  [`& .${classes.drawerPaper}`]: {
    background: '#202020',
    width: '100%',
  },

  [`& .${classes.content}`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  [`& .${classes.menu}`]: {
    position: 'relative',
    zIndex: ACTION_BANNER_Z_INDEX + 1,
  },
}));

interface Props {
  children: React.ReactNode;
}

const ChiefReportLayout = (props: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderDrawer = () => {
    return (
      <nav>
        <Drawer
          open={isDrawerOpen}
          classes={{paper: classes.drawerPaper}}
          variant='temporary'
          anchor={'right'}
          onClose={handleToggleDrawer}
        >
          <TopMenuNew onToggleDrawer={handleToggleDrawer} />
        </Drawer>
      </nav>
    );
  };

  return (
    <Root className={classes.root}>
      <CssBaseline />
      {renderDrawer()}
      <Hidden mdUp>
        <nav>
          <Box
            className={classes.menuMobile}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link href='/'>
              <img alt='AdSensor Logo' height='26' src={logoImg} width='115' />
            </Link>
            <Box>
              <IconButton
                color='inherit'
                edge='start'
                onClick={handleToggleDrawer}
                className={classes.menuMobileExpandButton}
                size='large'
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </nav>
      </Hidden>
      <Box className={classes.content}>
        <Box>
          <Hidden mdDown>
            <Box className={classes.menu}>
              <TopMenuNew />
            </Box>
          </Hidden>
        </Box>

        <Container maxWidth='xl'>
          <Header />
        </Container>

        {props.children}
      </Box>
    </Root>
  );
};

export default ChiefReportLayout;
