/** @format */

import {useState, useEffect, useMemo} from 'react';

import {Box, LinearProgress} from '@mui/material';
import {gql, useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';
import {add, format} from 'date-fns';

import Table from './Table';
import TableHeader from './Table/TableHeader';
import TableBody from './Table/TableBody';
import TableRow from './Table/TableRow';

import {type TABLE_DIRECTION} from './Table/TableHeader';

const STAFF_ID = 1;
const PROJECTS_ID = 2;
const DOWNLOADS_ID = 3;

const headerRows = [
  {
    id: STAFF_ID,
    title: 'Сотрудник',
    isSortable: true,
  },
  {
    id: PROJECTS_ID,
    title: 'Проекты',
    isSortable: true,
  },
  {
    id: DOWNLOADS_ID,
    title: 'Загрузки отчетов',
    isSortable: true,
  },
];

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      companyReportDownloads(startDate: $startDate, endDate: $endDate) {
        connectedUser {
          id
          uuid
          profile {
            name
          }
        }
        downloadMetrics {
          company {
            uuid
            name
          }
          downloads
        }
      }
    }
  }
`;
const Staff = () => {
  const sumDownloadMetrics = data =>
    data.reduce((acc, cur) => {
      return acc + cur.downloads;
    }, 0);

  let [searchParams] = useSearchParams();

  const now = new Date();

  const searchQuery = searchParams.get('searchQuery');

  const [expandedRows, setExpandedRows] = useState<[]>([]);

  const [sortedId, setSortedId] = useState<number>(DOWNLOADS_ID);
  const [tableSortDirection, setTableSortDirection] =
    useState<TABLE_DIRECTION>('desc');
  const sortedValue = tableSortDirection === 'asc' ? -1 : 1;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const {loading, data} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate || add(now, {days: -31}), 'yyyy-MM-dd'),
      endDate: format(endDate || now, 'yyyy-MM-dd'),
    },
  });

  useEffect(() => {
    const periodRange = searchParams.get('periodRange')?.split(', ');

    if (periodRange) {
      setStartDate(new Date(periodRange[0]));
      setEndDate(new Date(periodRange[1]));
    }
  }, [searchParams.get('periodRange')]);

  const handleSort = (id: number, direction: TABLE_DIRECTION) => {
    setSortedId(id);
    setTableSortDirection(direction);
  };

  const sortedData = useMemo(() => {
    if (!data) return [];

    const {companyReportDownloads} = data.currentUser;
    const result = [...companyReportDownloads];

    if (sortedId === STAFF_ID) {
      result.sort((a, b) =>
        a.connectedUser.profile.name.toLowerCase() <
        b.connectedUser.profile.name.toLowerCase()
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === DOWNLOADS_ID) {
      result.sort((a, b) =>
        sumDownloadMetrics(a.downloadMetrics) <
        sumDownloadMetrics(b.downloadMetrics)
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    if (sortedId === PROJECTS_ID) {
      result.sort((a, b) =>
        a.downloadMetrics.length < b.downloadMetrics.length
          ? sortedValue
          : -1 * sortedValue,
      );
    }

    return result;
  }, [sortedId, sortedValue, data]);

  const filteredData = useMemo(() => {
    const result = [...sortedData];

    if (searchQuery) {
      return result.filter(data =>
        data.company.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    return result;
  }, [sortedData, searchQuery]);

  if (loading) {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LinearProgress />
      </Box>
    );
  }

  const handleRowExpand = (id, data) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter(rowId => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleExpandAll = () => {
    if (expandedRows.length) {
      setExpandedRows([]);
    } else {
      setExpandedRows(tableData.map(row => row.connectedUser.id));
    }
  };

  const amountOfWeeks = Math.round(
    (endDate - startDate) / (7 * 24 * 60 * 60 * 1000),
  );

  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <Table type='staff'>
        <TableHeader
          type='staff'
          headerData={headerRows}
          handleExpandAll={handleExpandAll}
          isExpanded={Boolean(expandedRows.length)}
          isExpandable
          handleSort={handleSort}
          sortedId={sortedId}
          sortDirection={tableSortDirection}
        />

        <TableBody>
          {filteredData.map((data, index) => (
            <TableRow
              key={index}
              type='staff'
              rowData={data}
              amountOfWeeks={amountOfWeeks}
              onClick={() => handleRowExpand(data.connectedUser.id, data)}
              isExpanded={expandedRows.includes(data.connectedUser.id)}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Staff;
