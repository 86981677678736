/** @format */

import {Navigate, Route, Routes} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import PrivateRoute from 'components/PrivateRoute';

import ChiefReportLayout from 'layouts/ChiefReportLayout';

import Summary from './Summary';
import Staff from './Staff';

const Home = () => {
  return (
    <ChiefReportLayout>
      <Routes>
        <Route
          path={`${ROUTES.CHIEF_REPORT_SUMMARY}/*`}
          element={
            <PrivateRoute>
              <Summary />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.CHIEF_REPORT_STAFF}/*`}
          element={
            <PrivateRoute>
              <Staff />
            </PrivateRoute>
          }
        />
        <Route index element={<Navigate to={ROUTES.CHIEF_REPORT_SUMMARY} />} />
      </Routes>
    </ChiefReportLayout>
  );
};

export default Home;
