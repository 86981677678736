/** @format */

import {Box, Grid, Typography, useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useTheme} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import BoxPlate from 'components/plates/BoxPlate';
import BudgetScoreStatus from 'components/BudgetScoreStatus';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import type {Check} from 'types';
import {
  GOOD_BREAKPOINT,
  ALMOST_GOOD_BREAKPOINT,
  NORMAL_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/budgetScoreBreakpoints';

const PREFIX = 'PerformanceNew';

const classes = {
  container: `${PREFIX}-container`,
  boxPlateContainer: `${PREFIX}-boxPlateContainer`,
  headerTitleContainer: `${PREFIX}-headerTitleContainer`,
  headerTitle: `${PREFIX}-headerTitle`,
  headerSubtitleContainer: `${PREFIX}-headerSubtitleContainer`,
  updatedText: `${PREFIX}-updatedText`,
  headerSubtitle: `${PREFIX}-headerSubtitle`,
  link: `${PREFIX}-link`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
  statusContainer: `${PREFIX}-statusContainer`,
  statusTitle: `${PREFIX}-statusTitle`,
  statusDescription: `${PREFIX}-statusDescription`,
  statusItem: `${PREFIX}-statusItem`,
  dynamicImage: `${PREFIX}-dynamicImage`,
  recommendationsContainer: `${PREFIX}-recommendationsContainer`,
  recommendationsAmount: `${PREFIX}-recommendationsAmount`,
  plateContainer: `${PREFIX}-plateContainer`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
  },

  [`& .${classes.boxPlateContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  [`& .${classes.headerTitleContainer}`]: {
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.headerTitle}`]: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '24px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
      lineHeight: '30px',
    },
  },

  [`& .${classes.headerSubtitleContainer}`]: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.updatedText}`]: {
    color: '#9F9F9F',
    fontSize: 12,
    fontWeight: 400,
  },

  [`& .${classes.headerSubtitle}`]: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 400,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
  },

  [`& .${classes.buttonContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.button}`]: {
    height: 48,
    padding: theme.spacing(4, 8),
    borderRadius: '8px',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.statusContainer}`]: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: theme.spacing(6.5),
      '& > div': {
        width: '100%',
      },
    },
  },

  [`& .${classes.statusTitle}`]: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.statusDescription}`]: {
    color: '#8B8B8B',
    fontSize: 14,
    fontWeight: 400,
  },

  [`& .${classes.statusItem}`]: {
    width: 56,
    height: 56,
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  [`& .${classes.dynamicImage}`]: {
    marginRight: theme.spacing(1.5),
  },

  [`& .${classes.recommendationsContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 220,
    height: 56,
    borderRadius: '8px',
    background: '#2B2B2B',

    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.169px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.recommendationsAmount}`]: {
    marginTop: theme.spacing(1),
    color: '#F1F1F1',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  [`& .${classes.plateContainer}`]: {
    borderRadius: '8px',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },
}));

type Props = {
  budgetScore: number;
  isLaunched: boolean;
  lastChecks: Check[];
};

const PerformanceNew = (props: Props) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    navigate('/home/main/graphs');
  };
  const renderHeader = () => {
    return (
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} className={classes.headerTitleContainer}>
            <Typography className={classes.headerTitle}>
              <Trans>Оценка эффективности</Trans>
            </Typography>
          </Grid>

          <Grid container item spacing={1} xs={12} sm={4}>
            <Grid
              item
              xs={12}
              className={classes.headerSubtitleContainer}
              style={{alignItems: isMobile ? 'flex-start' : 'flex-end'}}
            >
              <TransparentSmallButton
                className={classes.button}
                onClick={handleClick}
              >
                <img
                  className={classes.dynamicImage}
                  src={new URL('./images/dynamic.svg', import.meta.url).href}
                />
                <Trans>История</Trans>
              </TransparentSmallButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderBudgetScore = () => {
    const statusTitle = () => {
      if (props.isLaunched) {
        return <Trans>Кампании на проверке</Trans>;
      }

      if (props.budgetScore === GOOD_BREAKPOINT) {
        return <Trans>РК работают без замечаний</Trans>;
      }

      if (
        props.budgetScore > NORMAL_BREAKPOINT &&
        props.budgetScore <= ALMOST_GOOD_BREAKPOINT
      ) {
        return <Trans>РК работают почти без замечаний</Trans>;
      }

      if (
        props.budgetScore >= UNOPTIMAL_BREAKPOINT &&
        props.budgetScore <= NORMAL_BREAKPOINT
      ) {
        return <Trans>РК работают не оптимально</Trans>;
      }

      return <Trans>РК работают не эффективно</Trans>;
    };

    const statusDescription = () => {
      if (props.isLaunched) {
        return <Trans>Первый отчет готовится</Trans>;
      }

      if (props.budgetScore === GOOD_BREAKPOINT) {
        return <Trans>Доработка не требуется</Trans>;
      }

      if (
        props.budgetScore > NORMAL_BREAKPOINT &&
        props.budgetScore <= ALMOST_GOOD_BREAKPOINT
      ) {
        return <Trans>Доступны рекомендации по улучшению</Trans>;
      }

      if (
        props.budgetScore >= UNOPTIMAL_BREAKPOINT &&
        props.budgetScore <= NORMAL_BREAKPOINT
      ) {
        return <Trans>Требуется доработка</Trans>;
      }

      return <Trans>Требуется немедленная доработка</Trans>;
    };

    const newRecommendations = props.lastChecks.reduce((acc, cur) => {
      return acc + cur.new;
    }, 0);

    return (
      <BoxPlate>
        <Box
          className={classes.plateContainer}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Box
            className={classes.statusContainer}
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'flex-start',
            }}
          >
            <BudgetScoreStatus
              statusClassName={classes.statusItem}
              isLaunched={props.isLaunched}
              budgetScore={props.budgetScore}
            />

            <Box
              sx={{
                ml: 3,
              }}
            >
              <Typography className={classes.statusTitle}>
                {statusTitle()}
              </Typography>
              <Typography className={classes.statusDescription} variant='body2'>
                {statusDescription()}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.recommendationsContainer}>
            {newRecommendations ? (
              <Box>
                <Trans>Новые</Trans>

                <Typography className={classes.recommendationsAmount}>
                  {newRecommendations}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Trans>Рекомендаций нет</Trans>
              </Box>
            )}
          </Box>
        </Box>
      </BoxPlate>
    );
  };

  return (
    <StyledBox className={classes.container}>
      <Box className={classes.boxPlateContainer}>
        {renderHeader()}
        <Box
          sx={{
            mt: 8,
          }}
        >
          {renderBudgetScore()}
        </Box>
      </Box>
    </StyledBox>
  );
};

export default PerformanceNew;
