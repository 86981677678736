/** @format */

import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';

import * as Sentry from '@sentry/react';

import adTypeHelpers from 'helpers/adTypeHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const AuthContext = createContext();

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN';

// https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/
export const AuthProvider = props => {
  const {mixpanel} = useMixpanelContext();
  const {switchCompany, switchAdType} = useCompanyContext();

  // JSON.parse('undefined') -> parsing error
  // JSON.parse('null') -> null
  const initialValue = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
  const [authToken, setAuthToken] = useState(initialValue);

  const saveToLocalStorage = authToken => {
    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(authToken));
  };

  // https://mixpanel.com/report/2271652/view/2818548/setup/
  // https://developer.mixpanel.com/docs/nodejs#setting-profile-properties
  // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties#reserved-properties-for-user-profiles
  const saveToMixpanel = useCallback(user => {
    const {id, uuid, phone, email, roles, type, budget, insertedAt, profile} = user;
    const name = profile && profile.name;

    // Create or update user profile
    mixpanel.people.set({
      $created: insertedAt,
      $name: name,
      $phone: phone,
      $email: email,
      created: insertedAt,
      name,
      phone,
      email,
      roles,
      type,
      budget,
      site_id: id,
      uuid,
    });

    // Register super properties
    //
    // > In general, Property values that may change over time and whose change
    // > is valuable data may be best tracked as both a Profile Property and a
    // > Super Property.
    mixpanel.register({name, roles, type, budget});
  }, [mixpanel]);

  const signUp = useCallback(authToken => {
    // https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
    mixpanel.alias(authToken.user.uuid);
    mixpanel.track('sign_up');

    // Send event to GTM
    if (window.dataLayer) {
      window.dataLayer.push({event: 'app.sign_up'});
    }

    saveToLocalStorage(authToken);
    setAuthToken(authToken);
    saveToMixpanel(authToken.user);
    Sentry.setUser({id: authToken.user.uuid});
  }, [mixpanel, saveToMixpanel]);

  const signIn = useCallback(authToken => {
    // https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
    mixpanel.identify(authToken.user.uuid);
    mixpanel.people.increment('sign_in_count');
    mixpanel.track('sign_in');

    saveToLocalStorage(authToken);
    setAuthToken(authToken);
    saveToMixpanel(authToken.user);
    Sentry.setUser({id: authToken.user.uuid});

    // firstCompany can be null if user has not completed wizard
    if (authToken.user.firstCompany) {
      const {firstCompany} = authToken.user;
      const adType = adTypeHelpers.initialAdType(firstCompany.tokens);

      switchCompany(firstCompany);
      switchAdType(adType);
    }
  }, [mixpanel, saveToMixpanel, switchAdType, switchCompany]);

  const signOut = useCallback(() => {
    mixpanel.track('sign_out');
    // https://developer.mixpanel.com/docs/javascript#call-reset-at-logout
    // https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
    mixpanel.reset();

    localStorage.removeItem(AUTH_TOKEN_KEY);
    switchCompany(null);
    switchAdType(null);
    Sentry.setUser(null);
    setAuthToken(null);
  }, [mixpanel, switchAdType, switchCompany]);

  const contextValue = useMemo(() => ({
    authToken,
    signIn,
    signOut,
    signUp
  }), [authToken, signIn, signOut, signUp]);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
