/** @format */

import {ChangeEvent, useEffect, useMemo, useState} from 'react';

import {styled} from '@mui/material/styles';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import {clsx} from 'clsx';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {parseISO} from 'date-fns';
import {useNavigate, useLocation} from 'react-router-dom';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Breadcrumbs from 'components/Breadcrumbs';
import DemoModal from 'components/DemoModal';
import LegacyTextField from 'components/inputs/LegacyTextField';
import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';
import PrimarySmallButton from 'components/buttons/PrimarySmallButton';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import isNewVersion from 'helpers/isNewVersionHelper';

import routerHelpers from 'helpers/routerHelpers';

import type {UserRole} from 'types';

const PREFIX = 'GET_CURRENT_USER';

const classes = {
  addButtonContainer: `${PREFIX}-addButtonContainer`,
  pending: `${PREFIX}-pending`,
  deleted: `${PREFIX}-deleted`,
  headerTitle: `${PREFIX}-headerTitle`,
  addButton: `${PREFIX}-addButton`,
  searchInput: `${PREFIX}-searchInput`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellStatus: `${PREFIX}-tableCellStatus`,
  tableCellStatusArchived: `${PREFIX}-tableCellStatusArchived`,
  actionButton: `${PREFIX}-actionButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.addButtonContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.pending}`]: {
    color: '#F14B4B',
  },

  [`& .${classes.deleted}`]: {
    color: 'rgba(255, 255, 255, 0.5)',
  },

  [`& .${classes.headerTitle}`]: {
    color: '#F1F1F1',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
  },

  [`& .${classes.addButton}`]: {
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
  },

  [`& .${classes.searchInput}`]: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #202020',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.131px',
  },

  [`& .${classes.tableRow}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.tableCell}`]: {
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.169px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(1)': {
        width: '25% !important',
      },
      '&:nth-of-type(2),&:nth-of-type(3) ': {
        width: '20% !important',
      },
      '&:nth-of-type(4)': {
        width: '17% !important',
      },
      '&:nth-of-type(5)': {
        width: 'auto !important',
      },
    },
  },

  [`& .${classes.tableCellStatus}`]: {
    color: '#677C41',
  },

  [`& .${classes.tableCellStatusArchived}`]: {
    color: '#AE5658',
  },

  [`& .${classes.actionButton}`]: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#2B2B2B',
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
    },
  },
}));

interface connectedUser {
  id: number;
  profile: {
    name: string;
    position: string;
  };
  uuid: string;
  email: string;
  status: string;
  insertedAt: string;
}

interface statusById {
  [key: number]: boolean | undefined;
}

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      roles
      connectedUsers {
        id
        profile {
          name
          position
        }
        uuid
        email
        status
        insertedAt
        companies {
          id
        }
      }
      restrictions {
        connectedUsersCount
      }
      subscription {
        isTrial
      }
    }
  }
`;

const DELETE = gql`
  mutation ($connectedUserId: ID!) {
    deleteConnectedUser(connectedUserId: $connectedUserId) {
      id
    }
  }
`;

export const RENEW = gql`
  mutation ($connectedUserId: ID!) {
    renewConnectedUser(connectedUserId: $connectedUserId) {
      id
    }
  }
`;

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '',
    title: <Trans>Доступы</Trans>,
  },
];

export const ConnectedUserList = () => {
  const [itemsDeleting, setItemsDeleting] = useState({} as statusById);
  const [itemsRenewing, setItemsRenewing] = useState({} as statusById);
  const [search, setSearch] = useState('');
  const [displayItems, setDisplayItems] = useState([] as connectedUser[]);
  const [showDemoModal, setShowDemoModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {t} = useTranslation();

  const isNewVersionEnabled = isNewVersion();

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER);
  const [deleteUser] = useMutation(DELETE);
  const [renewUser] = useMutation(RENEW);

  routerHelpers.useCallbackOnReturn(refetch);

  useEffect(() => {
    if (!data) return;

    const {connectedUsers} = data.currentUser;
    if (search === '') setDisplayItems(connectedUsers);

    const lowerCaseSearch = search.toLowerCase();
    const items = R.pipe(
      R.filter(
        (v: connectedUser) =>
          v.profile.name.toLowerCase().indexOf(lowerCaseSearch) >= 0 ||
          v.email.toLowerCase().indexOf(lowerCaseSearch) >= 0,
      ),
      R.sortBy(v => v.id),
    )(connectedUsers as connectedUser[]);

    setDisplayItems(items);
  }, [data, search]);

  const isDemo = useMemo(() => {
    if (!data) return true;

    const roles = data.currentUser.roles as UserRole[];
    if (roles == null) return false;

    return roles.includes('DEMO');
  }, [data]);

  const canAdd = useMemo(() => {
    if (!data) return true;

    const {connectedUsersCount} = data.currentUser.restrictions;
    const {connectedUsers} = data.currentUser;

    if (connectedUsersCount == null) {
      return true;
    }

    const activeItems = R.filter(
      (v: connectedUser) => v.status != 'DELETED',
      connectedUsers as connectedUser[],
    );

    return activeItems.length < connectedUsersCount;
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const handleClickAdd = () => {
    if (isDemo) {
      setShowDemoModal(true);
    } else {
      navigate('/home/settings/connected_users/new', {
        state: {background: location},
      });
    }
  };

  const handleClickEdit = (id: number) => {
    navigate(`/home/settings/connected_users/${id}`, {
      state: {background: location},
    });
  };

  const handleClickDelete = async (id: number) => {
    if (isDemo) {
      setShowDemoModal(true);
    } else {
      setItemsDeleting({...itemsDeleting, [id]: true});
      try {
        await deleteUser({variables: {connectedUserId: id}});
      } finally {
        refetch();
        setItemsDeleting({...itemsDeleting, [id]: false});
      }
    }
  };

  const handleClickRenew = async (id: number) => {
    if (isDemo) {
      setShowDemoModal(true);
    } else {
      setItemsRenewing({...itemsRenewing, [id]: true});
      try {
        await renewUser({variables: {connectedUserId: id}});
      } finally {
        refetch();
        setItemsRenewing({...itemsRenewing, [id]: false});
      }
    }
  };

  const handleChangeSearch = (event: ChangeEvent<{value: string}>) => {
    setSearch(event.target.value);
  };

  const {isTrial} = data.currentUser.subscription;

  const handleCloseDemoModal = () => {
    setShowDemoModal(false);
  };

  return isNewVersionEnabled ? (
    <Root>
      <Box
        sx={{
          mb: 10,
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
      </Box>
      <Grid
        container
        spacing={6}
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={7}>
          <Typography className={classes.headerTitle}>
            <Trans>Управление доступами</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.addButtonContainer}>
          <PrimaryMediumButton
            className={classes.addButton}
            disabled={!canAdd}
            aria-label='new-connected-user-button'
            onClick={handleClickAdd}
          >
            <Box
              sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineRounded fontSize='small' />
            </Box>
            <Trans>Добавить сотрудника</Trans>
          </PrimaryMediumButton>
        </Grid>
        {!canAdd && (
          <Grid item xs={12}>
            <Typography>
              {!isTrial && (
                <Trans>
                  Исчерпан лимит установленный для вашего тарифа. Для расширения
                  возможностей - обратитесь к вашему менеджеру.
                </Trans>
              )}
              {isTrial && (
                <Trans>
                  Исчерпан лимит установленный для вашего триал-режима. Для
                  расширения возможностей - обратитесь к вашему менеджеру.
                </Trans>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <LegacyTextField
            className={classes.searchInput}
            placeholder={t('Поиск по имени или email')}
            inputProps={{'aria-label': 'search-input'}}
            onChange={handleChangeSearch}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 6,
        }}
      >
        <Box>
          <Table>
            {displayItems.map((v: connectedUser) => {
              const date = parseISO(v.insertedAt);
              const formattedDate = t('{{formattedDate, datetime}}', {
                formattedDate: date,
                formatParams: {
                  formattedDate: {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  },
                },
              });
              const isPending = v.status === 'PENDING';
              const isDeleted = v.status === 'DELETED';
              const canDelete = v.status === 'ACTIVE' || v.status === 'PENDING';
              const canEdit = v.status === 'ACTIVE' || v.status === 'PENDING';
              const canRenew = v.status === 'DELETED';

              return (
                <Box
                  key={v.id}
                  className={clsx(classes.tableRow, {
                    [classes.deleted]: isDeleted,
                  })}
                >
                  <Box className={classes.tableCell}>{v.profile.name}</Box>
                  <Box className={classes.tableCell}>{v.email}</Box>
                  <Box className={classes.tableCell}>{formattedDate}</Box>
                  <Box
                    className={clsx(
                      classes.tableCell,
                      classes.tableCellStatus,
                      {
                        [classes.pending]: isPending,
                        [classes.tableCellStatusArchived]: isDeleted,
                      },
                    )}
                  >
                    {t(`connected_user_status.${v.status}`)}
                  </Box>
                  <Box className={classes.tableCell}>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      {canEdit && (
                        <IconButton
                          className={classes.actionButton}
                          color='primary'
                          aria-label='edit-button'
                          onClick={() => handleClickEdit(v.id)}
                          size='large'
                        >
                          <EditOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      {canDelete && itemsDeleting[v.id] && <CircularProgress />}
                      {canDelete && !itemsDeleting[v.id] && (
                        <IconButton
                          className={classes.actionButton}
                          color='primary'
                          aria-label='delete-button'
                          onClick={() => handleClickDelete(v.id)}
                          size='large'
                        >
                          <RemoveCircleOutlineIcon fontSize='small' />
                        </IconButton>
                      )}
                      {canRenew && itemsRenewing[v.id] && <CircularProgress />}
                      {canRenew && !itemsRenewing[v.id] && (
                        <IconButton
                          className={classes.actionButton}
                          color='primary'
                          aria-label='renew-button'
                          onClick={() => handleClickRenew(v.id)}
                          size='large'
                        >
                          <RestoreIcon fontSize='small' />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Table>
        </Box>
      </Box>
      {showDemoModal && <DemoModal onClose={handleCloseDemoModal} />}
    </Root>
  ) : (
    <Root>
      <Grid
        container
        spacing={6}
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={7}>
          <Typography variant='h1'>
            <Trans>Управление доступами</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.addButtonContainer}>
          <PrimarySmallButton
            disabled={!canAdd}
            aria-label='new-connected-user-button'
            onClick={handleClickAdd}
          >
            <Trans>Добавить сотрудника</Trans>
          </PrimarySmallButton>
        </Grid>
        {!canAdd && (
          <Grid item xs={12}>
            <Typography>
              {!isTrial && (
                <Trans>
                  Исчерпан лимит установленный для вашего тарифа. Для расширения
                  возможностей - обратитесь к вашему менеджеру.
                </Trans>
              )}
              {isTrial && (
                <Trans>
                  Исчерпан лимит установленный для вашего триал-режима. Для
                  расширения возможностей - обратитесь к вашему менеджеру.
                </Trans>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={7}>
          <LegacyTextField
            placeholder={t('Поиск по имени или email')}
            inputProps={{'aria-label': 'search-input'}}
            onChange={handleChangeSearch}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 6,
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>N</TableCell>
                <TableCell align='left'>
                  <Trans>Имя</Trans>
                </TableCell>
                <TableCell align='left'>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell align='left'>
                  <Trans>Добавлен</Trans>
                </TableCell>
                <TableCell align='left'>
                  <Trans>Статус</Trans>
                </TableCell>
                <TableCell align='left' />
              </TableRow>
            </TableHead>
            <TableBody>
              {displayItems.map((v: connectedUser, i: number) => {
                const date = parseISO(v.insertedAt);
                const formattedDate = t('{{formattedDate, datetime}}', {
                  formattedDate: date,
                  formatParams: {
                    formattedDate: {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    },
                  },
                });
                const isPending = v.status === 'PENDING';
                const isDeleted = v.status === 'DELETED';
                const canDelete =
                  v.status === 'ACTIVE' || v.status === 'PENDING';
                const canEdit = v.status === 'ACTIVE' || v.status === 'PENDING';
                const canRenew = v.status === 'DELETED';
                return (
                  <TableRow
                    key={v.id}
                    className={clsx({[classes.deleted]: isDeleted})}
                  >
                    <TableCell size='small'>{i + 1}</TableCell>
                    <TableCell>{v.profile.name}</TableCell>
                    <TableCell>{v.email}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell className={clsx({[classes.pending]: isPending})}>
                      {t(`connected_user_status.${v.status}`)}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                      >
                        {canEdit && (
                          <IconButton
                            color='primary'
                            aria-label='edit-button'
                            onClick={() => handleClickEdit(v.id)}
                            size='large'
                          >
                            <EditIcon fontSize='small' />
                          </IconButton>
                        )}
                        {canDelete && itemsDeleting[v.id] && (
                          <CircularProgress />
                        )}
                        {canDelete && !itemsDeleting[v.id] && (
                          <IconButton
                            color='primary'
                            aria-label='delete-button'
                            onClick={() => handleClickDelete(v.id)}
                            size='large'
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        )}
                        {canRenew && itemsRenewing[v.id] && (
                          <CircularProgress />
                        )}
                        {canRenew && !itemsRenewing[v.id] && (
                          <IconButton
                            color='primary'
                            aria-label='renew-button'
                            onClick={() => handleClickRenew(v.id)}
                            size='large'
                          >
                            <RestoreIcon fontSize='small' />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {showDemoModal && <DemoModal onClose={handleCloseDemoModal} />}
    </Root>
  );
};
