/** @format */
import {useState, useRef, useEffect} from 'react';

import {Box, Button} from '@mui/material';
import {clsx} from 'clsx';
import {styled} from '@mui/material/styles';
import StraightIcon from '@mui/icons-material/Straight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';
import {useSearchParams} from 'react-router-dom';
import {Trans} from 'react-i18next';

import {CHIER_REPORT_TABLE_TYPE} from 'pages/ChiefReport/types';

import TableCell from './TableCell';
import ArrowIcon from './ArrowIcon';

import {RESOLVED_AVG_ID, RESOLVED_MIN_ID, RESOLVED_MAX_ID} from '../Summary';

const PREFIX = 'TableHeader';

const classes = {
  container: `${PREFIX}-container`,
  cell: `${PREFIX}-cell`,
  sortable: `${PREFIX}-sortable`,
  mainCell: `${PREFIX}-mainCell`,
  staffCell: `${PREFIX}-staffCell`,
  wide: `${PREFIX}-wide`,
  sortStraight: `${PREFIX}-sortStraight`,
  sortStraightRotated: `${PREFIX}-sortStraightRotated`,
  helpIcon: `${PREFIX}-helpIcon`,
  helpButton: `${PREFIX}-helpButton`,
  joinedContainer: `${PREFIX}-joinedContainer`,
  joinedCell: `${PREFIX}-joinedCell`,
};

const Root = styled('div')(({theme}) => ({
  width: '100vw',
  overflow: 'hidden',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 2,
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    background: '#202020',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  [`& .${classes.joinedContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classes.joinedCell}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '35px',
    background: '#202020',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: '0.1px',
    '&:first-of-type': {
      position: 'relative',
      zIndex: 2,
    },
  },
  [`& .${classes.cell}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.sortable}`]: {
    cursor: 'pointer',
    '&:hover': {
      color: '#DFDFDF !important',
    },
  },
  [`& .${classes.wide}`]: {
    width: '400px !important',
  },
  [`& .${classes.mainCell}`]: {
    color: '#DFDFDF !important',
  },
  [`& .${classes.sortStraight}`]: {
    color: '#DFDFDF',
    fontSize: '16px',
  },
  [`& .${classes.sortStraightRotated}`]: {
    transform: 'rotateX(190deg)',
  },
  [`& .${classes.helpButton}`]: {
    minWidth: 'auto',
    width: '16px',
    height: '16px',
  },
  [`& .${classes.helpIcon}`]: {
    marginLeft: theme.spacing(2),
    fontSize: '16px',
    color: '#8B8B8B',
  },
}));

const PopupBody = styled('div')(
  ({theme}) => `
  width: 320px;
  padding: 12px;
  margin: 8px;
  border-radius: 8px;
  box-shadow: ${
    theme.palette.mode === 'dark'
      ? '0px 4px 8px rgb(0 0 0 / 0.7)'
      : '0px 4px 8px rgb(0 0 0 / 0.1)'
  };
  background: white;
  color: black;
  position: relative;
  z-index: 3
`,
);

export type TABLE_DIRECTION = 'asc' | 'desc';

interface Props {
  type: CHIER_REPORT_TABLE_TYPE;
  headerData: any;
  isExpanded?: boolean;
  isExpandable?: boolean;
  handleExpandAll?: () => void;
  handleSort: (id: number, direction: TABLE_DIRECTION) => void;
  sortDirection: TABLE_DIRECTION;
  sortedId: number;
}

const TableHeader = (props: Props) => {
  const headerRef = useRef(null);

  let [searchParams] = useSearchParams();

  const [currentDescription, setCurrentDescription] = useState('');
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    text: string,
  ) => {
    event.stopPropagation();

    setAnchor(event.currentTarget);
    setCurrentDescription(text);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  const renderCell = (data, index) => {
    return (
      <TableCell
        key={data.id}
        className={clsx(
          classes.cell,
          data.isSortable && classes.sortable,
          data.isWide && classes.wide,
          props.sortedId === data.id && classes.mainCell,
        )}
        isHeader
        isSlim={(index === 3 && true) || (index === 4 && true)}
        isStaffTable={props.type === 'staff'}
        onClick={
          data.isSortable
            ? () =>
                props.handleSort(
                  data.id,
                  props.sortDirection === 'asc' ? 'desc' : 'asc',
                )
            : () => {}
        }
      >
        {props.sortedId === data.id && (
          <StraightIcon
            className={clsx(
              classes.sortStraight,
              props.sortDirection === 'asc' && classes.sortStraightRotated,
            )}
          />
        )}
        {data.title}

        {data.description && (
          <Button
            className={classes.helpButton}
            onMouseEnter={e => handlePopoverOpen(e, data.description)}
            onMouseLeave={handlePopoverClose}
          >
            <HelpOutlineIcon className={classes.helpIcon} />
          </Button>
        )}
      </TableCell>
    );
  };

  useEffect(() => {
    if (headerRef.current) {
      const scrollLeft = searchParams.get('scrollLeft');
      headerRef.current.scrollLeft = scrollLeft;
    }
  }, [headerRef.current, searchParams.get('scrollLeft')]);

  return (
    <Root>
      <Box className={classes.container} ref={headerRef}>
        {props.isExpandable && (
          <TableCell
            isTiny
            onClick={props.handleExpandAll && props.handleExpandAll}
          >
            <ArrowIcon isExpanded={props.isExpanded || false} />
          </TableCell>
        )}
        {props.type === 'summary' ? (
          <>
            {props.headerData &&
              props.headerData
                .filter(
                  data =>
                    data.id !== RESOLVED_AVG_ID &&
                    data.id !== RESOLVED_MIN_ID &&
                    data.id !== RESOLVED_MAX_ID,
                )
                .map((data, index) => renderCell(data, index))}
            <Box className={classes.joinedContainer}>
              <Box className={classes.joinedCell}>
                <Trans>Время исправления, дней</Trans>
              </Box>
              <Box className={classes.joinedCell}>
                {renderCell(
                  props.headerData[RESOLVED_AVG_ID - 1],
                  RESOLVED_AVG_ID - 1,
                )}
                {renderCell(
                  props.headerData[RESOLVED_MIN_ID - 1],
                  RESOLVED_MIN_ID - 1,
                )}
                {renderCell(
                  props.headerData[RESOLVED_MAX_ID - 1],
                  RESOLVED_MAX_ID - 1,
                )}
              </Box>
            </Box>
          </>
        ) : (
          <>
            {props.headerData &&
              props.headerData.map((data, index) => renderCell(data, index))}
          </>
        )}
      </Box>
      <BasePopup id={id} open={open} anchor={anchor}>
        <PopupBody>{currentDescription}</PopupBody>
      </BasePopup>
    </Root>
  );
};

export default TableHeader;
