/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useSearchParams} from 'react-router-dom';

import {CHIER_REPORT_TABLE_TYPE} from 'pages/ChiefReport/types';

const PREFIX = 'Table';

const classes = {
  container: `${PREFIX}-container`,
};

const Root = styled(Box)<{type: CHIER_REPORT_TABLE_TYPE}>(
  ({theme, type = 'summary'}) => ({
    [`& .${classes.container}`]: {
      position: 'relative',
      width: '98vw',

      height: 'auto',
      maxHeight: 'calc(100vh - 200px)',
      overflow: 'scroll',
      border: '1px solid #2B2B2B',
      borderRadius: '8px',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '7px',
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        border: '1px solid rgba(255,255,255,0.5)',
        '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
      },

      '&::-webkit-scrollbar:vertical ': {
        width: '11px',
      },

      '&::-webkit-scrollbar:horizontal ': {
        height: '11px',
      },

      [theme.breakpoints.down('xl')]: {
        width: '98vw',
      },
    },
  }),
);

interface Props {
  type: CHIER_REPORT_TABLE_TYPE;
  children: React.ReactNode;
}

const Table = (props: Props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleTableScroll = e => {
    const searchQuery = searchParams.get('searchQuery');

    setSearchParams({
      searchQuery: searchQuery || '',
      scrollLeft: e.currentTarget.scrollLeft,
    });
  };
  return (
    <Root type={props.type}>
      <Box className={classes.container} onScroll={handleTableScroll}>
        {props.children}
      </Box>
    </Root>
  );
};

export default Table;
