/** @format */
import {useMemo} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Typography, Link} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {clsx} from 'clsx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import BudgetScoreStatus from 'components/BudgetScoreStatus';

import {
  GOOD_BREAKPOINT,
  ALMOST_GOOD_BREAKPOINT,
  NORMAL_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/budgetScoreBreakpoints';

const PREFIX = 'Row';

const classes = {
  rowContainer: `${PREFIX}-rowContainer`,
  dataLoadingContainer: `${PREFIX}-dataLoadingContainer`,
  errorsContainer: `${PREFIX}-errorsContainer`,
  rowTitle: `${PREFIX}-rowTitle`,
  errorsTitle: `${PREFIX}-errorsTitle`,
  statusItem: `${PREFIX}-statusItem`,
  badStatus: `${PREFIX}-badStatus`,
  normalStatus: `${PREFIX}-normalStatus`,
  goodStatus: `${PREFIX}-goodStatus`,
  unOptimalStatus: `${PREFIX}-unOptimalStatus`,
  neutralStatus: `${PREFIX}-neutralStatus`,
  errorsInstructionsLink: `${PREFIX}-errorsInstructionsLink`,
};

const Root = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '49%',

  [`& .${classes.rowContainer}`]: {
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '8px',
    background: '#2B2B2B',
    cursor: 'pointer',
    '&:hover': {
      background: '#383838',
    },
  },
  [`& .${classes.errorsContainer}`]: {
    width: '100%',
    minHeight: '84px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.dataLoadingContainer}`]: {
    width: '100%',
    minHeight: '84px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.rowTitle}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0.32px',
  },

  [`& .${classes.errorsTitle}`]: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.32px',
  },

  [`& .${classes.statusItem}`]: {
    height: '100% !important',
    borderRadius: '8px !important',
    background: 'red',
  },
  [`& .${classes.badStatus}`]: {
    backgroundColor: 'rgba(234, 87, 89, 0.20) !important',
  },
  [`& .${classes.normalStatus}`]: {
    backgroundColor: 'rgba(221, 161, 77, 0.20) !important',
  },
  [`& .${classes.goodStatus}`]: {
    backgroundColor: 'transparent !important',
  },
  [`& .${classes.unOptimalStatus}`]: {
    backgroundColor: 'rgba(234, 87, 89, 0.20) !important',
  },

  [`& .${classes.errorsInstructionsLink}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgba(234, 87, 89, 0.20)',
  },
}));

const Row = props => {
  const {t} = useTranslation();

  const statusItemClassName = useMemo(() => {
    if (props.budgetScore === GOOD_BREAKPOINT) {
      return classes.goodStatus;
    }

    if (
      props.budgetScore > NORMAL_BREAKPOINT &&
      props.budgetScore <= ALMOST_GOOD_BREAKPOINT
    ) {
      return classes.normalStatus;
    }

    if (
      props.budgetScore >= UNOPTIMAL_BREAKPOINT &&
      props.budgetScore <= NORMAL_BREAKPOINT
    ) {
      return classes.unOptimalStatus;
    }

    return classes.badStatus;
  }, [props.budgetScore, classes]);

  return (
    <Root onClick={props.onClick}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
        className={classes.rowContainer}
      >
        {props.lastCheckCollectDate === null && !props.areNoTokens ? (
          <Box className={classes.dataLoadingContainer}>
            {t('Данные собираются')}
          </Box>
        ) : (
          <Box className={classes.errorsContainer}>
            <Box
              sx={{
                padding: 2,
              }}
            >
              <Typography className={classes.rowTitle}>
                <Trans>
                  {props.adType === 'CONTEXT' ? 'Контекст' : 'Таргет'}
                </Trans>
              </Typography>
              {(props.isNoAccessToAccount || props.areNoTokens) && (
                <span
                  className={clsx(
                    props.isNoAccessToAccount && classes.errorsTitle,
                  )}
                >
                  {props.isNoAccessToAccount &&
                    `${t('Проблема с доступом к кабинету рекламной системы')}`}

                  {props.areNoTokens &&
                    `${t('В проекте нет подключенных рекламных кабинетов')}`}
                </span>
              )}
              {(Boolean(props.recomendations) || Boolean(props.newErrors)) && (
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: 2,
                  }}
                >
                  {Boolean(props.recomendations) && (
                    <Box
                      sx={{
                        marginRight: 4,
                      }}
                    >
                      <span className={classes.errorsTitle}>
                        {t('Сработало')}{' '}
                      </span>
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        {' '}
                        {props.recomendations}
                      </Box>
                    </Box>
                  )}
                  {Boolean(props.newErrors) && (
                    <div>
                      <span className={classes.errorsTitle}>{t('Новых')} </span>
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        {props.newErrors}
                      </Box>
                    </div>
                  )}
                </Box>
              )}
            </Box>
            {props.isNoAccessToAccount || props.areNoTokens ? (
              <Link
                className={classes.errorsInstructionsLink}
                href={`${
                  props.areNoTokens
                    ? 'https://adsensor.ru/instructions#!/tab/764353588-1'
                    : 'https://adsensor.ru/instructions#!/tab/764353588-2'
                }`}
                target='_blank'
                onClick={e => {
                  e.stopPropagation();
                }}
                underline='none'
              >
                <HelpOutlineIcon htmlColor='#EA5759' />
              </Link>
            ) : (
              <BudgetScoreStatus
                isLaunched={props.isLaunched}
                budgetScore={props.budgetScore}
                statusClassName={clsx(classes.statusItem, statusItemClassName)}
                isColorfullIcons={true}
              />
            )}
          </Box>
        )}
      </Box>
    </Root>
  );
};

export default Row;
