/** @format */

import {Box, Container, CssBaseline, Hidden, Link} from '@mui/material';
import {styled} from '@mui/material/styles';

import TopMenu from 'components/menus/TopMenu';
import TopMenuNew from 'components/menus/TopMenuNew';
import logoUrl from 'images/logo__white.svg';

import isNewVersion from 'helpers/isNewVersionHelper';

const PREFIX = 'SettingsLayout';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  menu: `${PREFIX}-menu`,
  logo: `${PREFIX}-logo`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    height: '100%',
  },

  [`& .${classes.content}`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  // use padding instead of margin because Firefox ignores marginBottom
  [`& .${classes.container}`]: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(22),
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(22),
      paddingTop: theme.spacing(12),
    },
  },

  [`& .${classes.menu}`]: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },

  [`& .${classes.logo}`]: {
    position: 'absolute',
    marginTop: theme.spacing(9),
    marginLeft: theme.spacing(15),
  },
}));

const SettingsLayout = props => {
  const isNewVersionEnabled = isNewVersion();

  const renderDrawer = () => {
    return (
      <nav className={classes.logo}>
        <Hidden lgDown>
          <Link href='/'>
            <img alt='AdSensor logo' height='30' src={logoUrl} width='136' />
          </Link>
        </Hidden>
      </nav>
    );
  };

  return (
    <Root className={classes.root}>
      <CssBaseline />
      {isNewVersionEnabled ? (
        <Box>
          <TopMenuNew />

          <Container maxWidth='md'>{props.children}</Container>
        </Box>
      ) : (
        <>
          {renderDrawer()}

          <Box className={classes.content}>
            <Container className={classes.container} maxWidth='sm'>
              <Box className={classes.menu}>
                <TopMenu />
              </Box>

              {props.children}
            </Container>
          </Box>
        </>
      )}
    </Root>
  );
};

export default SettingsLayout;
