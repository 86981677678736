/** @format */
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';

type BgColor = 'green' | 'red' | 'yellow';

const Root = styled(Box)<{value: number; bgColor: BgColor}>(
  ({theme, value, bgColor}) => ({
    maxWidth: '100px',
    width: `${value}%`,
    minWidth: `${value > 0 ? '8px' : 'auto'}`,
    height: '8px',
    background:
      bgColor === 'red'
        ? '#EA5759'
        : bgColor === 'yellow'
          ? '#DDA14D'
          : '#749F23',
    marginLeft: theme.spacing(2),
    borderRadius: '8px',
  }),
);

interface Props {
  bgColor: BgColor;
  value: number;
}

const ResolvedLine = (props: Props) => {
  return <Root value={props.value} bgColor={props.bgColor} />;
};

export default ResolvedLine;
