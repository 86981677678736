/** @format */

import {clsx} from 'clsx';
import {Box} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {styled} from '@mui/material/styles';

const PREFIX = 'ArrowIcon';

const classes = {
  arrowIcon: `${PREFIX}-arrowIcon`,
  arrowIconRotated: `${PREFIX}-arrowIconRotated`,
  arrowIconTransparent: `${PREFIX}-arrowIconTransparent`,
};

const Root = styled(Box)(({_theme}) => ({
  [`& .${classes.arrowIcon}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    transform: 'rotateX(190deg)',
    cursor: 'pointer',
  },
  [`& .${classes.arrowIconRotated}`]: {
    transform: 'rotateX(0deg)',
  },
  [`& .${classes.arrowIconTransparent}`]: {
    color: 'transparent',
  },
}));

interface Props {
  isExpanded: boolean;
  isTransparent?: boolean;
}

const ArrowIcon = (props: Props) => {
  return (
    <Root>
      <KeyboardArrowUpIcon
        className={clsx(
          classes.arrowIcon,
          props.isExpanded && classes.arrowIconRotated,
          props.isTransparent && classes.arrowIconTransparent,
        )}
      />
    </Root>
  );
};

export default ArrowIcon;
