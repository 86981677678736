/** @format */

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, IconButton, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import isNewVersion from 'helpers/isNewVersionHelper';

import AdTypePlatforms from 'components/icons/AdTypePlatforms';
import {AdType, NotificationChannelType} from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#202020',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    notificationChannel: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '18px',
    },
    row: {
      border: '1px solid #E0E0E0',
      borderRadius: 8,
      marginTop: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    actionButtonsContainer: {
      display: 'flex',
    },
    actionButton: {
      width: '48px',
      height: '48px',
      marginRight: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      background: '#2B2B2B',
      '&:last-of-type': {
        marginRight: '0',
      },
      [theme.breakpoints.down('md')]: {
        flexGrow: '1',
      },
    },
    textNew: {
      color: '#FFF',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '0.131px',
    },
    notificationChannelNew: {
      marginTop: theme.spacing(2),
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '0.131px',
    },
    rowNew: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(4),
      borderRadius: '8px',
      background: '#2B2B2B',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      '&:first-of-type': {
        marginTop: 0,
      },
    },
  }),
);

interface Props {
  notification: {
    id: number;
    channelType: NotificationChannelType;
    company: {
      name: string;
    };
    emailChannel: {
      email?: string;
    };
    adTypes: AdType[];
  };
  onEdit: () => void;
  onDelete: () => void;
}

const Row = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const isNewVersionEnabled = isNewVersion();

  const channelName = () => {
    if (props.notification.channelType === 'TELEGRAM') {
      return 'Бот в Телеграме';
    }
    return props.notification.emailChannel.email;
  };

  const text = (adType: AdType) => {
    if (adType === 'CONTEXT') return t('Контекст', 'Контекст');
    if (adType === 'TARGET') return t('Таргет', 'Таргет');

    return null;
  };

  return isNewVersionEnabled ? (
    <Box className={classes.container}>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography className={classes.textNew}>
            {props.notification.company.name}
          </Typography>
          <Typography className={classes.notificationChannelNew}>
            {channelName()}
          </Typography>
        </Box>

        <Box className={classes.actionButtonsContainer}>
          <IconButton
            className={classes.actionButton}
            aria-label='edit-notification-button'
            onClick={props.onEdit}
            size='large'
          >
            <EditOutlinedIcon htmlColor='#fff' fontSize='small' />
          </IconButton>
          <IconButton
            className={classes.actionButton}
            aria-label='delete-notification-button'
            onClick={props.onDelete}
            size='large'
          >
            <DeleteForeverOutlinedIcon htmlColor='#fff' fontSize='small' />
          </IconButton>
        </Box>
      </Box>

      {props.notification.adTypes.map((v, i) => (
        <Box key={i} className={classes.rowNew}>
          <Box
            display='flex'
            flexGrow={1}
            justifyContent='space-between'
            alignItems='center'
          >
            {text(v)}
            <AdTypePlatforms adTypes={[v]} size={20} />
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <Box className={classes.container}>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography className={classes.text}>
            {props.notification.company.name}
          </Typography>
          <Typography className={classes.notificationChannel}>
            {channelName()}
          </Typography>
        </Box>

        <Box>
          <IconButton
            size='small'
            aria-label='edit-notification-button'
            onClick={props.onEdit}
          >
            <EditIcon fontSize='small' />
          </IconButton>
          <IconButton
            size='small'
            aria-label='delete-notification-button'
            onClick={props.onDelete}
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Box>
      </Box>

      {props.notification.adTypes.map((v, i) => (
        <Box key={i} className={classes.row}>
          <Box
            display='flex'
            flexGrow={1}
            justifyContent='space-between'
            alignItems='center'
          >
            {text(v)}
            <AdTypePlatforms adTypes={[v]} size={20} bw />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Row;
