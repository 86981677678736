/** @format */

import React, {createContext, useContext} from 'react';

import mixpanel from 'mixpanel-browser';

import {AuthToken} from 'types';

interface Props {
  children: React.ReactNode;
}

interface MixpanelContext {
  setup: (authToken?: AuthToken) => void;
  mixpanel: Mixpanel;
}

const warnStubMessage = 'using mixpanel stub. Use MixpanelProvider for creating mixpanel instance';

const mixpanelStub = {
  setup: () => {
    // eslint-disable-next-line no-console
    console.warn(warnStubMessage);
  },
  mixpanel: {
    // eslint-disable-next-line no-console
    track: () => {console.warn(warnStubMessage);},
    // eslint-disable-next-line no-console
    register: () => {console.warn(warnStubMessage);},
    // eslint-disable-next-line no-console
    identify: () => {console.warn(warnStubMessage);},
    // eslint-disable-next-line no-console
    reset: () => {console.warn(warnStubMessage);},
  }
};

const MixpanelContext = createContext<MixpanelContext>(mixpanelStub);

// https://developer.mixpanel.com/docs/javascript-full-api-reference
export const MixpanelProvider = (props: Props) => {
  const setup = (authToken?: AuthToken) => {
    // eslint-disable-next-line
    mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN);

    const debug = !import.meta.env.PROD;
    // https://developer.mixpanel.com/docs/javascript#mixpanel-cookie
    // eslint-disable-next-line
    mixpanel.set_config({debug, ignore_dnt: true, secure_cookie: true});

    if (authToken) {
      // In general it's okay to call identify multiple times
      // (say, on each page load)
      // eslint-disable-next-line
      mixpanel.identify(authToken.user.uuid);
    }
  };

  return (
    <MixpanelContext.Provider value={{mixpanel: (mixpanel as Mixpanel), setup}}>
      {props.children}
    </MixpanelContext.Provider>
  );
};

export const useMixpanelContext = () => useContext(MixpanelContext);
