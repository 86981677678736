/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {clsx} from 'clsx';

const PREFIX = 'TableCell';

const classes = {
  container: `${PREFIX}-container`,
  headerContainer: `${PREFIX}-headerContainer`,
  staffHeaderContainer: `${PREFIX}-staffHeaderContainer`,
  tinyContainer: `${PREFIX}-tinyContainer`,
  slimContainer: `${PREFIX}-slimContainer`,
  wideContainer: `${PREFIX}-wideContainer`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    width: '200px',
    height: '100%',
    paddingRight: theme.spacing(4),
    background: 'transparent',
    color: '#DFDFDF',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: '0.1px',
  },
  [`& .${classes.headerContainer}`]: {
    width: '200px',
    height: '70px',
    padding: theme.spacing(0, 4, 0, 0),
    background: '#202020',
    color: '#8B8B8B',
  },
  [`& .${classes.staffHeaderContainer}`]: {
    width: '270px',
    padding: theme.spacing(0, 2),
  },
  [`& .${classes.tinyContainer}`]: {
    width: '34px',
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.slimContainer}`]: {
    width: '150px',
  },
  [`& .${classes.wideContainer}`]: {
    width: '400px',
  },
}));

interface Props {
  isHeader?: boolean;
  isStaffTable?: boolean;
  className?: string;
  isTiny?: boolean;
  isSlim?: boolean;
  isWide?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const TableCell = (props: Props) => {
  return (
    <Root>
      <Box
        className={clsx(
          classes.container,
          props.className && props.className,
          props.isHeader && classes.headerContainer,
          props.isTiny && classes.tinyContainer,
          props.isSlim && classes.slimContainer,
          props.isWide && classes.wideContainer,
          props.isStaffTable && classes.staffHeaderContainer,
        )}
        onClick={props.onClick}
      >
        {props.children}
      </Box>
    </Root>
  );
};

export default TableCell;
